import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const PACKAGING = loader("../../graphql/packaging/query/packaging.graphql")

export const getPackaging = async (id: number) => {
    const response = await apolloClient.query({
        query: PACKAGING,
        variables: { packagingId: id }
    })

    return { packaging: response.data.packaging }
}
