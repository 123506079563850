import { Link } from "react-router-dom"
import { useLoaderData } from "react-router-dom"
import Button from "@mui/material/Button"
import { Container } from "../../../components/Container"
import styled from "styled-components"

const DataWrapper = styled.div`
padding:20px
`

export const SortingCenters = () => {
    const { sortingCenters } = useLoaderData() as any;

    return (
        <Container>
            <h1>Centres de tri</h1>
            <Link to="/admin/centres-de-tri/nouveau">
                <Button variant="contained">+ Centre de tri</Button>
            </Link>

            <DataWrapper>
                <ul>
                    {sortingCenters.map((center: any) => {
                        return (
                            <Link to={`/admin/centres-de-tri/${center.id}`}>
                                <li key={center.id}>
                                    {center.name}
                                </li>
                            </Link>

                        )
                    })}
                </ul>
            </DataWrapper>
        </Container>
    )
}