import { getCollectPoints } from "../../api/collectPoint/getCollectPoints";
import { getCollectPointDetails } from "../../api/collectPoint/getCollectPointDetails"

export const collectPointsLoader = async () => {
    const collectPoints = await getCollectPoints();
    return collectPoints;
}

export const collectPointDetailsLoader = async ({ params }: any) => {
    const collectPoint = await getCollectPointDetails(Number(params.id))
    return collectPoint
}