import { getCompanies } from "../../api/company/getCompanies";
import { getCompany } from "../../api/company/getCompany";

export const companiesLoader = async () => {
    const companies = await getCompanies();
    return companies;
}

export const companyLoader = async ({ params }: any) => {
    const company = await getCompany(Number(params.id));
    return company;
}