import { useState } from 'react';
import { ButtonLine, ButtonsAndTable, TableContainer } from '../../components/grid/ButtonsAndTable';
import { Button } from '@mui/material';
import { getPackagings } from '../../api/packaging/getPackagings';

import { CreatePackagingModal } from './CreatePackagingModal';
import { PackagingsTable } from './PackagingsTable';
import { useLoaderData } from 'react-router-dom';

export const Packagings = () => {
    const { packagingTypes, packagings } = useLoaderData() as any;
    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            <CreatePackagingModal openModal={openModal} setOpenModal={setOpenModal} packagingTypes={packagingTypes} />
            <ButtonsAndTable>
                <ButtonLine>
                    <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={() => setOpenModal(true)}>
                        Créer un emballage
                    </Button>
                </ButtonLine>
                <TableContainer>
                    <PackagingsTable packagings={packagings} />
                </TableContainer>
            </ButtonsAndTable >
        </>
    )
}