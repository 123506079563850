import { useParams, useNavigate } from "react-router-dom"
import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { loader } from 'graphql.macro';

import { Container } from "../../../components/Container"
import { Loader } from "../../../components/Loader"
import { useMutation } from "@apollo/client";

import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle";

const CREATE_PACKAGING_UNIT = loader('../../../graphql/packaging/mutation/createPackagingUnit.graphql');

export const CreatePackagingUnits = ({ params }: any) => {
    let { id } = useParams()
    const [quantityToCreate, setQuantityToCreate] = useState<number>(0)
    const [createPackagingUnit, { loading, error, data }] = useMutation(CREATE_PACKAGING_UNIT);

    const navigate = useNavigate()

    const handleCreateUnits = async () => {
        await createPackagingUnit({
            variables: {
                packagingId: Number(id),
                quantity: quantityToCreate
            },
            onCompleted: () => navigate(`/emballages/${id}`)
        })
    }

    return (
        <Container>
            <BackButtonAndTitle title={"Nouvelles unités d'emballages"} />
            <div style={{ padding: 10 }}>
                <p style={{ marginBottom: 10 }}>Combien d'unités souhaitez-vous créer ?</p>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 400,
                    gap: 10
                }}>
                    <TextField type="number" placeholder="Quantité" onChange={e => setQuantityToCreate(Number(e.target.value))} />
                    {(!loading && quantityToCreate > 0) && <Button fullWidth={false} onClick={handleCreateUnits}>Créer {quantityToCreate} unités</Button>}
                    {loading && <Loader />}
                </div>
            </div>
        </Container>
    )
}