import { useState, useEffect } from "react"
import { Button, TextField } from "@mui/material"
import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle"
import { Container } from "../../../components/Container"
import { SuggestionContainer } from "../../../components/AddressAutoComplete"
import styled from "styled-components"
import { Loader } from "../../../components/Loader"
import { loader } from "graphql.macro"
import { apolloClient } from "../../../api/apolloClient"
import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import { ErrorMessage } from "../../../components/ErrorMessage"

const AutoCompleteContainer = styled.div`
    max-width:300px;
    padding-top:15px
`

const SelectedUnitContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:10px;
    max-width:300px;
    margin-top: 10px;
    margin-bottom:20px
`

const SelectedUnit = styled.div`
    padding:5px;
    border:solid 1px #ccc;
    border-radius:4px;
    display:flex;
    align-items:center;
    gap:5px
`

const GET_PACKAGING_UNIT = loader('../../../graphql/packagingUnit/query/packagingUnit.graphql');
const ASSIGN_PACKAGING_UNITS_TO_COLLECT_POINT = loader('../../../graphql/sortingCenter/mutation/attachPackagingUnitsToSortingCenter.graphql');

const defaultUnit = {
    id: "",
    packaging: {
        name: ""
    },
    currentCollectPoint: {
        id: -1,
        name: ''
    }
}

export const AssignPackagingUnitsToCenter = ({ params }: any) => {
    const navigate = useNavigate()
    let { id } = useParams()
    const [inputValue, setInputValue] = useState("")
    const [loadingQuery, setLoadingQuery] = useState(false);
    const [fetchedUnit, setFetchedUnit] = useState(defaultUnit)
    const [error404, setError404] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState<any[]>([])

    let [assignUnitsMutation, { data, loading, error }] = useMutation(ASSIGN_PACKAGING_UNITS_TO_COLLECT_POINT);

    useEffect(() => {
        // Fonction de délai (debounce) pour retarder l'exécution de la requête
        if (inputValue.trim() !== '') {
            setLoadingQuery(true)
        }

        const delaySearch = setTimeout(async () => {
            if (inputValue.trim() !== '') {
                setError404(false)
                try {
                    setLoadingQuery(true);
                    console.log("loading. . .")
                    const response = await apolloClient.query({
                        query: GET_PACKAGING_UNIT,
                        variables: {
                            packagingUnitId: Number(inputValue)
                        }
                    })
                    if (response.data) {
                        setLoadingQuery(false);
                        setFetchedUnit(response.data.packagingUnit)
                    }
                } catch (error) {
                    setError404(true)
                    setLoadingQuery(false);
                    setFetchedUnit(defaultUnit)
                }


            }
        }, 800); // Délai de 800ms avant de déclencher la requête

        // Effacer le délai précédent lorsqu'une nouvelle saisie est effectuée
        return () => {
            setFetchedUnit(defaultUnit)
            setError404(false)
            clearTimeout(delaySearch)
        };
    }, [inputValue]);

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value)
    }

    const handleSelectUnit = (unit: any) => {
        // check if unit is already in
        const unitIndex = selectedUnits.findIndex(u => u.id == unit.id)
        if (unitIndex == -1) {
            setSelectedUnits(prev => [...prev, unit])
        }
        setFetchedUnit(defaultUnit)

        setInputValue("")
    }

    const handleUnselectUnit = (unit: any) => {
        const unitToRemoveIndex = selectedUnits.findIndex(u => u.id == unit.id)
        if (unitToRemoveIndex !== -1) {
            const updatedSelectedUnits = [
                ...selectedUnits.slice(0, unitToRemoveIndex),
                ...selectedUnits.slice(unitToRemoveIndex + 1),
            ];

            // Mettre à jour l'état avec la nouvelle liste
            setSelectedUnits(updatedSelectedUnits);
        }
    }

    const handleAssignUnitsToCollectPoint = async () => {
        try {
            await assignUnitsMutation({
                variables: {
                    packagingUnitsIds: selectedUnits.map(u => String(u.id)),
                    sortingCenterId: Number(id)
                }
            })
            navigate("/admin/centres-de-tri/" + id)
        } catch (error) {
            console.log(error)
        }
    }

    console.log("Fetched Unit vvvv")
    console.log(fetchedUnit)

    return (
        <Container>
            <BackButtonAndTitle title="Assigner de nouvelles unités d'emballage" />

            <AutoCompleteContainer>
                <TextField value={inputValue} placeholder="ID de l'unité à ajouter" type="number" fullWidth onChange={handleInputChange} />
                {loadingQuery && <div style={{ marginTop: 10 }}><Loader /></div>}
                {error404 && <p>Pas d'unité correspondante à cet ID</p>}
                {(!loadingQuery && (fetchedUnit != defaultUnit)) && <SuggestionContainer onClick={() => handleSelectUnit(fetchedUnit)}>
                    <span>{fetchedUnit.packaging.name}</span> <b>#{fetchedUnit.id}</b>
                </SuggestionContainer>
                }
            </AutoCompleteContainer>

            <SelectedUnitContainer>
                {selectedUnits.map(unit => {
                    return (
                        <SelectedUnit>
                            <p>{unit.packaging.name}</p>
                            <div>
                                {(unit.currentCollectPoint && unit.currentCollectPoint.id != id) && <Tooltip title={`Déjà liée au pdc : ${unit.currentCollectPoint.name}.`}><WarningAmberIcon color="warning" /></Tooltip>}
                            </div>
                            <b>#{unit.id}</b>
                            <Button onClick={() => handleUnselectUnit(unit)}><DeleteIcon /></Button>
                        </SelectedUnit>
                    )
                })}
            </SelectedUnitContainer>

            {selectedUnits.length > 0 && <Button variant="contained" onClick={handleAssignUnitsToCollectPoint}>Sauvegarder</Button>}
            {selectedUnits.length > 0 && loading && <Loader />}
            {error && <ErrorMessage>Une erreur s'est produite...</ErrorMessage>}
        </Container >
    )
}