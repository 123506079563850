import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment"
import styled from 'styled-components';
import 'moment/locale/fr';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Loader } from '../Loader';
import { ErrorMessage } from '../ErrorMessage';
moment().locale("fr");

const Wrapper = styled.div`
display:flex;
align-items:center;
gap:10px
`

const UNDO_COLLECTION = loader("../../graphql/collectionHistory/mutation/undoCollection.graphql")
const DELETE_COLLECTION = loader("../../graphql/collectionHistory/mutation/deleteCollectionHistory.graphql")

export const CollectionHistory = (props: any) => {
    const history = props.history;
    const [undoCollection, { loading, data, error }] = useMutation(UNDO_COLLECTION)
    const [deleteCollection, deleteCollectionReponses] = useMutation(DELETE_COLLECTION)

    const handleUndo = async () => {
        try {
            await undoCollection({
                variables: {
                    historyId: history.id
                },
                onCompleted: (data) => {
                    props.setUnits((prev: any) => [...prev, data.undoCollection])
                    props.setCollectionHistories(props.collectionHistories.filter((h: any) => h.id != history.id))
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const handleDelete = async () => {
        try {
            await deleteCollection({
                variables: { historyId: history.id },
                onCompleted: (data) => {
                    props.setCollectionHistories(props.collectionHistories.filter((h: any) => h.id != history.id))
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Wrapper>
            <span>
                <b>Collecte unité #{history.unit.id}</b> - {moment(history.createdAt).format('LLL')} - <b>{history.fillRate}%</b>
            </span>
            {!loading && <UndoIcon color="action" style={{ cursor: "pointer" }} onClick={handleUndo} />}
            {loading && <Loader />}

            {!deleteCollectionReponses.loading && <DeleteIcon color="action" style={{ cursor: "pointer" }} onClick={handleDelete} />}
            {deleteCollectionReponses.loading && <Loader />}

            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            {deleteCollectionReponses.error && <ErrorMessage>{deleteCollectionReponses.error.message}</ErrorMessage>}
        </Wrapper>
    )
}