import { useState } from 'react';
import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { ButtonLine, ButtonsAndTable, TableContainer } from '../../components/grid/ButtonsAndTable';
import { Button } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { CreateCompanyModal } from './CreateCompanyModal';

import { CompaniesTable } from './CompaniesTable';
import { loader } from 'graphql.macro';


export const Companies = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <CreateCompanyModal openModal={openModal} setOpenModal={setOpenModal} />
            <ButtonsAndTable>
                <ButtonLine>
                    <Button onClick={() => setOpenModal(true)} variant="contained" color="primary" style={{ marginRight: 10 }}>
                        Créer une entreprise
                    </Button>
                </ButtonLine>
                <TableContainer>
                    <CompaniesTable />
                </TableContainer>
            </ButtonsAndTable >
        </>
    )
}