import { Button, InputLabel, TextField } from "@mui/material"
import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle"
import { Container } from "../../../components/Container"
import { TextFieldContainer } from "../../../components/form/TextFieldContainer"
import { FormContainer } from "../../../components/form/FormContainer"
import { useState } from "react"
import { isEmail } from "../../../data-validator/isEmail"
import { loader } from "graphql.macro"
import { useMutation } from "@apollo/client"
import { ErrorMessage } from "../../../components/ErrorMessage"
import { Loader } from "../../../components/Loader"
import { useNavigate, useParams } from "react-router-dom"

const ATTACH_USER_TO_SORTING_CENTER = loader("../../../graphql/admin/sortingCenter/mutation/attachUserToSortingCenter.graphql")

export const AttachUserToSortingCenter = () => {
    let { id } = useParams()
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>("")
    const [inputError, setInputError] = useState<string>("")

    const [attachUser, { loading, data, error }] = useMutation(ATTACH_USER_TO_SORTING_CENTER);

    const handleChange = (e: any) => {
        setEmail(e.target.value.toLowerCase())
    }

    const handleSubmit = async () => {
        if (!isEmail(email)) return setInputError("Email incorrecte");

        try {
            await attachUser({
                variables: {
                    centerId: Number(id),
                    email: email
                },
                onCompleted: () => {
                    navigate(-1)
                }
            })
        } catch (error: any) {
            console.log(error)
            setInputError(error.message)
        }
    }

    return (
        <Container>
            <BackButtonAndTitle title={"Attacher un utilisateur au centre"} />
            <FormContainer>
                <TextFieldContainer>
                    <InputLabel>Adresse mail</InputLabel>
                    <TextField placeholder="Adresse mail" fullWidth onChange={handleChange} />
                </TextFieldContainer>
                {(inputError.length > 0 && !loading) && <ErrorMessage>{inputError}</ErrorMessage>}
                {(email.length > 0 && !loading) && <Button onClick={handleSubmit} variant="contained" style={{ marginTop: 10 }}>Attacher</Button>}
                {loading && <Loader />}
            </FormContainer>
        </Container>
    )
}