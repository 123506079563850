import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const COLLECT_POINT_DETAILS = loader("../../graphql/collectPoint/query/collectPointDetails.graphql");

export const getCollectPointDetails = async (id: number) => {
    const { data } = await apolloClient.query({
        query: COLLECT_POINT_DETAILS,
        variables: {
            collectPointId: id
        }
    })

    return { collectPoint: data.collectPoint }
}