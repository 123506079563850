import styled from "styled-components"
import { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";

import { Logo } from "../../components/Logo";
import { Loader } from "../../components/Loader";
import { ErrorMessage } from "../../components/ErrorMessage";

export const Container = styled.div`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-top:50px;
  @media (max-width: 767px) {
    max-width: 90%;
    align-items:center;
  }
`;

const UNIT_COLLECT_POINT = loader("../../graphql/scan/query/unitCollectPoint.graphql")
const GET_PUBLIC_COLLECT_POINT = loader("../../graphql/scan/query/publicCollectPoints.graphql")
const SCAN_UNIT_FROM_COLLECT_POINT = loader("../../graphql/scan/mutation/scanUnitFromCollectPoint.graphql")

export const PackagingUnitScannedPage = () => {
  let [collectPointId, setCollectPointId] = useState()
  let [askForCollectPoint, setAskForCollectPoint] = useState(false)
  let [result, setResult] = useState({
    fullCratesQty: 0,
    collectPointName: ""
  })
  let [searchParams, setSearchParams] = useSearchParams();
  let { cryptedId } = useParams();
  const fillRate = searchParams.get("fillrate")
  const [scanMutation, scanResponse] = useMutation(SCAN_UNIT_FROM_COLLECT_POINT);
  const unitCollectPointResponse = useQuery(UNIT_COLLECT_POINT, { variables: { cryptedUnitId: cryptedId } })

  useEffect(() => {
    const scan = async () => {
      await scanMutation(
        {
          variables: {
            cryptedUnitId: cryptedId,
            collectPointId: Number(collectPointId),
            fillRate: Number(fillRate)
          },
          onCompleted: (data) => {
            setResult(data.scanUnitFromCollectPoint)
          }
        }
      )
    }

    // Si le collect point id a changé, on arrête de demander => on execute la mutation
    if (collectPointId) {
      setAskForCollectPoint(false)
      scan();
      return
    }

    // premier call à l'api
    if (unitCollectPointResponse.data) {
      if (unitCollectPointResponse.data.unitCollectPoint) {
        setCollectPointId(unitCollectPointResponse.data.unitCollectPoint.id)
      } else {
        return setAskForCollectPoint(true)
      }
    }


  }, [unitCollectPointResponse.loading, collectPointId])

  if (unitCollectPointResponse.loading) {
    return (
      <Container>
        <Logo />
        <Loader />
      </Container>
    )
  }

  if (askForCollectPoint) {
    return (
      <Container>
        <Logo />
        <AskWhichCollectPoint setCollectPointId={setCollectPointId} />
      </Container>
    )
  }

  if (collectPointId) {
    return (
      <Container>
        <Logo />
        {scanResponse.loading && <div style={{ marginTop: 20 }}><Loader /></div>}
        {!scanResponse.loading &&
          <>
            <CheckCircleIcon style={{ color: "green", fontSize: 100, marginTop: 20, marginBottom: 20 }} />
            <h3>{result.collectPointName}</h3>
            <p>La caisse est <b>pleine à {fillRate}%</b></p>
            <p>Vous avez <b>{result.fullCratesQty}</b> caisses pleines</p>
            <Button onClick={() => { setAskForCollectPoint(true) }}>Vous n'êtes pas {result.collectPointName} ?</Button>
          </>
        }
      </Container>
    )
  }

  return (
    <Container>
      <Logo />
      <Loader />
    </Container>
  )
}

const AskWhichCollectPoint = (props: any) => {
  const [error, setError] = useState("")
  const { data, loading } = useQuery(GET_PUBLIC_COLLECT_POINT)
  const [selectedCollectPoint, setSelectedCollectPoint] = useState("")

  if (loading) {
    return (<Loader />)
  }

  return (
    <div style={{ paddingTop: 50 }}>
      <h3>Quel magasin êtes-vous ?</h3>
      {loading && <Loader />}
      {!loading &&
        <>
          <InputLabel id="select-label">Sélectionner votre magasin</InputLabel>
          <Select
            labelId="select-label"
            fullWidth
            label="Sélectionner mon magasin"
            value={selectedCollectPoint}
            onChange={
              (e) => setSelectedCollectPoint(e.target.value)
            }
            style={{ marginBottom: 10 }}
          >
            {data.publicCollectPoints.map((cp: any) => {
              return (<MenuItem key={cp.id} value={cp.id}>{cp.name}</MenuItem>)
            })}
          </Select>

          {error != "" && <ErrorMessage>{error}</ErrorMessage>}

          <Button variant="contained" fullWidth onClick={() => {
            if (selectedCollectPoint != "") {
              props.setCollectPointId(selectedCollectPoint)
            } else {
              setError("Veuillez sélectionner un magasin")
            }
          }}>Sélectionner</Button>
        </>}

    </div>
  )
}