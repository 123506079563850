import styled from "styled-components";

// Create a grid with a button line container and a table container
export const ButtonsAndTable = styled.div`
    padding-top:10px;
    display: flex;
    width: 100%;
    overflow:scroll;
    flex-direction: column;
`;

// Create a button line container
export const ButtonLine = styled.div`
    padding: 0 1rem;
`;

// Create a table container
export const TableContainer = styled.div`
    flex:1
    display: flex;
    justify-content: center;
    height:85vh
`;
