import { useNavigate, useRouteError } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Button } from "@mui/material";
import { useEffect } from "react";

export const ErrorPage = () => {
    const error = useRouteError() as any;
    console.error(error);
    const navigate = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("jwt")) {
            navigate("/auth/login")
        }
    }, [])

    return (
        <div style={{ width: 250, padding: 20 }}>
            <h1>Erreur</h1>
            <p>Une erreur s'est produite...</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
            <Button onClick={() => navigate(-1)}>Retour</Button>
        </div>
    );
}