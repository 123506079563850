import { useState } from "react"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { loader } from "graphql.macro"
import { useMutation, useQuery } from '@apollo/client';
import { Loader } from '../../../components/Loader';
import { ErrorMessage } from "../../../components/ErrorMessage";
import * as Yup from 'yup';
import styled from "styled-components";
import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DeleteButton } from "../../../components/DeleteButton";
import { useFormik } from "formik";
import { Container } from "../../../components/Container";

const Box = styled.div`
    padding:10px
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`

const PackagingUnitsContainer = styled.div`
    margin-top: 20px
`

const UPDATE_PACKAGING = loader('../../../graphql/packaging/mutation/updatePackaging.graphql')
const DELETE_PACKAGING = loader('../../../graphql/packaging/mutation/deletePackaging.graphql')

export const Packaging = () => {
    const navigate = useNavigate();
    const { packaging, packagingTypes } = useLoaderData() as any;
    const [editing, setEditing] = useState(false)

    const [updatePackaging, { data, loading, error }] = useMutation(UPDATE_PACKAGING)
    const [deletePackaging, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_PACKAGING)

    const formik = useFormik({
        initialValues: {
            name: packaging.name,
            level: packaging.level,
            type: packaging.type,
            width: packaging.width,
            height: packaging.height,
            depth: packaging.depth,
            weight: packaging.weight,
            bottleMeanCapacity: packaging.bottleMeanCapacity
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            level: Yup.string().required('Champ requis'),
            bottleMeanCapacity: Yup.number().positive("La moyenne doit être >= 0").integer("Donner un chiffre entier")
        }),
        onSubmit: async values => {
            await updatePackaging({
                variables: {
                    ...{ updatePackagingId: packaging.id },
                    ...values
                }
            })
            if (!error) setEditing(false); navigate(`/emballages/${packaging.id}`)
        }
    })

    if (!editing) {
        return (
            <Container>
                <h1>{packaging.name}</h1>
                <p><b>Niveau d'emballage</b> : {packaging.level}</p>
                <p><b>Type d'emballage</b> : {packaging.type}</p>

                <h2>Caractéristiques</h2>
                <ul style={{ paddingLeft: 20 }}>
                    {packaging.height && <li><b>Hauteur</b> : {packaging.height} mm</li>}
                    {packaging.width && <li><b>Largeur</b> : {packaging.width} mm</li>}
                    {packaging.depth && <li><b>Profondeur</b> : {packaging.depth} mm</li>}
                    {packaging.weight && <li><b>Poids</b> : {packaging.weight} g</li>}
                    {packaging.weight && <li><b>Capacité moyenne de bouteilles</b> : {packaging.bottleMeanCapacity} bouteilles</li>}
                </ul>

                <Row style={{ marginTop: 20 }}>
                    <Button variant="contained" onClick={() => setEditing(true)}>Modifier</Button>
                    <DeleteButton onClick={
                        async () => {
                            await deletePackaging({
                                variables: {
                                    deletePackagingId: packaging.id
                                },
                                onCompleted: () => navigate(`/emballages`),
                                onError: (err) => { console.error(err.message) }
                            })
                        }
                    }>
                        {deleteLoading ? <Loader /> : 'Supprimer'}
                    </DeleteButton>
                    {deleteError && <ErrorMessage>{deleteError.message}</ErrorMessage>}
                </Row>

                <PackagingUnitsContainer>
                    <h2>Unités liées à l'emballage :</h2>
                    <ul style={{ paddingLeft: 20, marginBottom: 10 }}>
                        <li><b>Quantité</b> : {packaging.packagingUnits.length}</li>
                    </ul>
                    <Link to={`/emballages/${packaging.id}/nouvelles-unites`}>
                        <Button variant="outlined">Créer de nouvelles unités</Button>
                    </Link>

                    <Link to={`/emballages/${packaging.id}/generer-qr-codes`}>
                        <Button>Générer QR codes</Button>
                    </Link>
                </PackagingUnitsContainer>

            </Container>
        )
    } else {
        return (
            <Container>
                <h1>{packaging.name}</h1>
                <form style={{ maxWidth: 600 }} onSubmit={formik.handleSubmit}>
                    <InputLabel>Nom *</InputLabel>
                    <TextField
                        id="name"
                        label="Nom de l'emballage *"
                        variant="outlined"
                        fullWidth
                        {...formik.getFieldProps('name')}
                        error={formik.touched.name && formik.errors.name ? true : false}
                        margin="dense"
                    />


                    <InputLabel>level *</InputLabel>
                    <Select fullWidth {...formik.getFieldProps('level')}>
                        {packagingTypes.level.map((level: any) => {
                            return (
                                <MenuItem value={level}>{level}</MenuItem>
                            )
                        })
                        }
                    </Select>

                    <InputLabel>type</InputLabel>
                    <Select fullWidth {...formik.getFieldProps('type')}>
                        {packagingTypes.type.map((type: any) => {
                            return (
                                <MenuItem value={type}>{type}</MenuItem>
                            )
                        })
                        }
                    </Select>

                    <InputLabel>weight</InputLabel>
                    <TextField
                        id="weight"
                        label="Poids (g)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        margin="dense"
                        {...formik.getFieldProps('weight')}
                    />

                    <Row>
                        <div>
                            <InputLabel>width</InputLabel>
                            <TextField
                                id="width"
                                label="Largeur (mm)"
                                variant="outlined"
                                fullWidth
                                type="number"
                                margin="dense"
                                {...formik.getFieldProps('width')}
                            /></div>

                        <div>
                            <InputLabel>height</InputLabel>
                            <TextField
                                id="height"
                                label="Hauteur (mm)"
                                variant="outlined"
                                fullWidth
                                type="number"
                                margin="dense"
                                {...formik.getFieldProps('height')}
                            />
                        </div>

                        <div>
                            <InputLabel>depth</InputLabel>
                            <TextField
                                id="depth"
                                label="Profondeur (mm)"
                                variant="outlined"
                                fullWidth
                                type="number"
                                margin="dense"
                                {...formik.getFieldProps('depth')}
                            />
                        </div>
                    </Row>

                    <InputLabel>Capacité moyenne de bouteilles</InputLabel>
                    <TextField
                        id="bottleMeanCapacity"
                        label="Capacité moyenne de bouteilles"
                        variant="outlined"
                        fullWidth
                        type="number"
                        {...formik.getFieldProps('bottleMeanCapacity')}
                    />

                    {!loading && <Button variant="contained" style={{ marginTop: 20 }} type="submit">Sauvegarder</Button>}
                    {loading && <Loader />}
                    {error && <ErrorMessage>Une erreur est survenue</ErrorMessage>}
                </form>
            </Container>
        )
    }
}