import { Link } from 'react-router-dom';
import styled from "styled-components"
import { Logo } from '../Logo';
import { Button } from '@mui/material';
import { UserDropDown } from './UserDropDown';


let NavBarBox = styled.div`
    border: 1px solid rgba(0,0,0,0.1);
    display: flex;
    overflow: hidden;
    padding-top:15px;
    padding-bottom:15px;
    padding-left:15px;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.05);
`

const LogoBox = styled.div`
    display: flex;
`

const MenuBox = styled.div`
    display: flex;
    flex:1;
    align-items: center;
    justify-content: flex-end;
`

const Menu = styled.ul`
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

const MenuElement = styled.li`
    margin: 0 10px;
`

export const AdminNavBar = () => {
    return (
        <>
            <NavBarBox>
                <LogoBox>
                    <Link to="/admin">
                        <Logo />
                    </Link>
                </LogoBox>
                <MenuBox>
                    <Menu>
                        {/* <MenuElement>
                            <Link to="/entreprises" style={{ textDecoration: "none" }}>
                                <Button variant="text">
                                    Entreprises
                                </Button>
                            </Link>
                        </MenuElement>
                        <MenuElement>
                            <Link to="/produits" style={{ textDecoration: "none" }}>
                                <Button variant="text">
                                    Produits
                                </Button>
                            </Link>
                        </MenuElement> */}
                        <MenuElement>
                            <Link to="/admin/centres-de-tri" style={{ textDecoration: "none" }}>
                                <Button variant="text">
                                    Centres de tri
                                </Button>
                            </Link>
                        </MenuElement>
                        <MenuElement>
                            <UserDropDown />
                        </MenuElement>
                    </Menu>
                </MenuBox>
            </NavBarBox>
        </>
    );
}
