import { useEffect, useState } from "react"
import { Outlet, useNavigate, useNavigation } from "react-router-dom"
import { RootBox } from "../components/RootBox"
import { NavBar } from "../components/navigation/NavBar"
import { LinearProgress } from "@mui/material"
// Before the dom load, If there is no JWT on localstorage, redirect to login page


export const Root = () => {
    let navigate = useNavigate();
    let navigation = useNavigation();

    useEffect(() => {
        if (!localStorage.getItem("jwt")) {
            navigate("/auth/login")
        }
    }, [])

    return (
        <RootBox>
            <NavBar />
            {(navigation.state === "loading") && <LinearProgress style={{ position: "relative", top: 0 }} />}
            <Outlet />
        </RootBox>
    )
}