import styled from "styled-components"
const MyContainer = styled.div`
padding:10px
`
export const Container = (props: any) => {
    return (
        <MyContainer>
            {props.children}
        </MyContainer>
    )
}