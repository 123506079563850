import { useState } from "react";
import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/client";
import { Button, FormLabel, InputLabel, TextField } from "@mui/material";
import { loader } from "graphql.macro";
import Papa from "papaparse"
import { Container } from "../../../components/Container";
import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle";
import { FormContainer } from "../../../components/form/FormContainer";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { Loader } from "../../../components/Loader";

const GENERATE_QR_CODES = loader("../../../graphql/packagingUnit/mutation/generateUnitQRCodes.graphql")

export const GenerateUnitQRCodes = () => {
    const [generateQuery, { loading, data, error }] = useMutation(GENERATE_QR_CODES);
    let { id } = useParams();

    const [fillRates, setFillRates] = useState<String[]>([]);
    const [newFillRate, setNewFillRate] = useState('');
    let [errorMessage, setErrorMessage] = useState<String>("")



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setNewFillRate(inputValue);
    };

    const handleAddClick = () => {
        // Vérifiez si la nouvelle valeur est un nombre valide entre 0 et 100
        const newFR: number = parseFloat(newFillRate)
        if (!isNaN(newFR) && newFR >= 0 && newFR <= 100) {
            if (!fillRates.includes(newFillRate)) {
                setFillRates((prevFillRates: String[]) => [...prevFillRates, newFillRate]);
            }
            setNewFillRate(''); // Effacez le champ texte après l'ajout
            setErrorMessage("")
        } else {
            setErrorMessage("Veuillez entrer un nombre valide entre 0 et 100.")
        }
    };

    const handleGenerate = async () => {
        try {
            await generateQuery({
                variables: {
                    fillRates: fillRates,
                    packagingId: Number(id)
                },
                onCompleted: (data) => {
                    const csvData = data.generateUnitQrCodes.map((item: any) => ({
                        unitId: item.unitId,
                        url: item.url,
                        fillRate: item.fillRate,
                        packagingName: item.packagingName
                    }));
                    const csv = Papa.unparse(csvData)
                    const blob = new Blob([csv], { type: "text/csv" });
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.href = url;
                    a.download = "data.csv";
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
            })
        } catch (error) {
            setErrorMessage("Une erreur s'est produite lors de la génération")
        }
    }

    return (
        <Container>
            <BackButtonAndTitle title="Générer les QR codes des unités" />
            <b style={{ color: 'orange' }}>Génère uniquement les QR codes des unités d'emballage non activés</b>
            <FormContainer>
                <InputLabel>Quel(s) taux de remplissage souhaitez-vous mesurer ?</InputLabel>
                <TextField
                    placeholder="Taux entre 0% et 100%"
                    type="number"
                    fullWidth
                    value={newFillRate}
                    onChange={handleInputChange}
                />
                <Button onClick={handleAddClick}>Ajouter</Button>
                {errorMessage && errorMessage != "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <p>Taux de remplissage à mesurer :</p>
                <ul style={{ paddingLeft: 20, marginBottom: 20 }}>
                    {fillRates
                        .slice()
                        .sort((a, b) => Number(a) - Number(b)) // Trie par ordre croissant
                        .map((rate, index) => (
                            <li key={index}>{rate}%</li>
                        ))}
                </ul>
            </FormContainer>
            <br />
            {!loading && <Button variant="contained" onClick={handleGenerate}>Générer</Button>}
            {loading && <Loader />}
        </Container>
    )
}