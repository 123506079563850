import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from "../../../components/Container"
import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle"
import { TextField, InputLabel, Select, MenuItem, Button } from "@mui/material"
import { FormContainer } from "../../../components/form/FormContainer"
import { TextFieldContainer } from "../../../components/form/TextFieldContainer"

import { AddressAutoComplete } from "../../../components/AddressAutoComplete"
import { useEffect, useState } from "react"
import { Place } from "../../../types/Place"
import { ErrorMessage } from '../../../components/ErrorMessage';
import { Loader } from '../../../components/Loader';
import { useMutation } from '@apollo/client';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { loader } from 'graphql.macro';
import { DeleteButton } from '../../../components/DeleteButton';
const UPDATE_COLLECT_POINT = loader("../../../graphql/collectPoint/mutation/updateCollectPoint.graphql");
const DELETE_COLLECT_POINT = loader("../../../graphql/collectPoint/mutation/deleteCollectPoint.graphql");

const CPtypes = ["GMS", "PROXI", "CHR", "OTHER"]

export const UpdateCollectPoint = () => {
    const { collectPoint } = useLoaderData() as any
    const navigate = useNavigate()
    const [addressError, setAddressError] = useState<string>()
    let [updateCollectPoint, { data, loading, error }] = useMutation(UPDATE_COLLECT_POINT);
    let [deleteCollectPoint, deleteCollectPointResponse] = useMutation(DELETE_COLLECT_POINT)
    const [selectedPlace, setSelectedPlace] = useState<Place>(collectPoint.location)

    const formik = useFormik({
        initialValues: {
            name: collectPoint.name,
            type: collectPoint.type,
            address: "",
            zipCode: "",
            city: "",
            latitude: 0,
            longitude: 0
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Le nom du point de collecte est requis'),
            type: Yup.string().required('Le type du point de collecte est requis'),
        }),
        onSubmit: async (values: any) => {
            values.address = selectedPlace.address
            values.zipCode = selectedPlace.zipCode
            values.city = selectedPlace.city
            values.latitude = selectedPlace.latitude
            values.longitude = selectedPlace.longitude
            if (values.address == "" || (values.latitude == 0 && values.longitude == 0) || values.city == "") {
                return setAddressError("L'adresse est incomplète")
            }

            console.log(values)
            await updateCollectPoint({
                variables: {
                    ...values, ...{
                        updateCollectPointId: collectPoint.id
                    }
                },
                onCompleted: () => {
                    formik.resetForm();
                    navigate(-1)
                }
            })
        }
    })

    const handleDeleteCP = async () => {
        try {
            await deleteCollectPoint({
                variables: {
                    collectPointId: collectPoint.id
                },
                onCompleted: () => {
                    navigate("/points-de-collecte")
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Container>
            <BackButtonAndTitle title="Modifier le point collecte" />
            <FormContainer>
                <form onSubmit={formik.handleSubmit}>
                    <TextFieldContainer>
                        <InputLabel>Nom du point de collecte</InputLabel>
                        <TextField
                            id="name"
                            label="Nom"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            {...formik.getFieldProps('name')}
                            error={formik.touched.name && formik.errors.name ? true : false}
                        />
                    </TextFieldContainer>

                    <TextFieldContainer>
                        <InputLabel>Type</InputLabel>
                        <Select
                            id="type"
                            fullWidth label="Type"
                            {...formik.getFieldProps('type')}
                        >
                            {CPtypes.map((type: any) => {
                                return (
                                    <MenuItem value={type}>{type}</MenuItem>
                                )
                            })
                            }
                        </Select>
                    </TextFieldContainer>

                    <TextFieldContainer>
                        <AddressAutoComplete setSelectedPlace={setSelectedPlace} defaultValue={collectPoint ? collectPoint.location.address : ""} />
                    </TextFieldContainer>

                    {(error || deleteCollectPointResponse.error) && <ErrorMessage>Une errreur est survenue...</ErrorMessage>}
                    {addressError && <ErrorMessage>{addressError}</ErrorMessage>}
                    {(!loading && !deleteCollectPointResponse.loading) &&
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button style={{ marginRight: 10 }} variant="contained" type="submit">Sauvegarder</Button>
                            <DeleteButton onClick={handleDeleteCP}>Supprimer</DeleteButton>
                        </div>
                    }
                    {(loading || deleteCollectPointResponse.loading) && <Loader />}
                </form>
            </FormContainer>
        </Container>
    )
}