import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Loader } from '../../components/Loader';
import { useLoaderData, useNavigate } from 'react-router-dom';

const renderHeader = (params: any) => {
    return (
        <b>
            {params.colDef.headerName}
        </b >
    )
}

const columns = [
    {
        field: 'name',
        flex: 1,
        headerName: 'Nom de l\'entreprise',
        renderHeader

    },
    {
        field: "email",
        flex: 2,
        headerName: 'Email',
        renderHeader

    },
    {
        field: 'phone',
        flex: 1,
        headerName: 'Téléphone',
        renderHeader

    },
    {
        field: 'address',
        flex: 2,
        headerName: 'Adresse',
        renderHeader

    },
    {
        field: 'zipCode',
        flex: 1,
        headerName: 'Code postal',
        renderHeader

    },
    {
        field: 'city',
        flex: 1,
        headerName: 'Ville',
        renderHeader
    },
    {
        field: 'isCollectPoint',
        flex: 1,
        headerName: 'Point de collecte',
        renderHeader
    }
]

export const CompaniesTable = (props: any) => {
    const { companies } = useLoaderData() as any;
    const navigate = useNavigate();
    let rows = companies;

    if (props.selectedRows && props.selectedRows.length == 1) {
        let companyToEdit = rows.find((company: { id: any; }) => company.id == props.selectedRows[0])
        props.setSelectedCompanyToEdit(companyToEdit)
    }

    return (
        <>
            <DataGrid
                columns={columns}
                rows={rows}
                onRowClick={(e) => {
                    navigate(`/entreprises/${e.id}`)
                }}
                sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    },
                }}
            />
        </>)
}