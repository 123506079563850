import { useState } from "react"
import { MenuItem, Menu, IconButton } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';

export const UserDropDown = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDisconnect = () => {
        localStorage.removeItem('jwt');
        window.location.href = '/auth/login';
        handleClose()
    }

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <PersonIcon color="primary" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleDisconnect} style={{ color: "red" }}>Déconnexion</MenuItem>
            </Menu>
        </div>
    );
}