import { useState } from "react";
import { Paper } from "../Paper"
import styled from "styled-components"
import { Button, Chip, Divider, MenuItem, Select } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from "graphql.macro"
import { Loader } from "../Loader";
import { ErrorMessage } from "../ErrorMessage";

export const HeadLine = styled.div`
    display:flex;
    margin-bottom:100px;
    flex-direction:column
`
export const BottomLine = styled.div`
    display:flex;
`

const UNLINK_UNIT = loader('../../graphql/collectPoint/mutation/unlinkPackagingUnitFromCollectPoint.graphql');
const UPDATE_UNIT_FILL_RATE = loader("../../graphql/packagingUnit/mutation/updateUnitFillRate.graphql")
const UPDATE_UNIT_STATUS = loader("../../graphql/packagingUnit/mutation/updateUnitStatus.graphql")
const GET_UNIT_STATUS = loader("../../graphql/packagingUnit/query/PackagingUnitStatusValues.graphql")

export const PackagingUnit = ({ unit, currentCollectPointId, setUnits, units, setCollectionHistories }: any) => {
    let [unlinkUnit, { data, loading, error }] = useMutation(UNLINK_UNIT);
    let [updateFillRate, updateFillRateResponse] = useMutation(UPDATE_UNIT_FILL_RATE)
    let [updateStatus, updateStatusResponse] = useMutation(UPDATE_UNIT_STATUS)
    let [isEditingFillRate, setIsEditingFillRate] = useState<Boolean>(false)
    let [newFillRate, setNewFillRate] = useState<number>(unit.fillRate)
    let [isEditingStatus, setIsEditingStatus] = useState<Boolean>(false)
    let [newStatus, setNewStatus] = useState(unit.status)
    let getUnitStatusResponse = useQuery(GET_UNIT_STATUS)

    const handleUnlink = async (props: any) => {
        try {
            await unlinkUnit({
                variables: {
                    unitId: unit.id,
                    collectPointId: currentCollectPointId
                },
                onCompleted: (data) => {
                    setUnits(units.filter((u: any) => u.id != unit.id))
                    setCollectionHistories((prev: any) => [...prev, data.unlinkPackagingUnitFromCollectPoint])
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleFillRateUpdate = (e: any) => {
        const inputValue = e.target.value;

        const fillRate = parseFloat(inputValue);

        if (!isNaN(fillRate) && fillRate >= 0 && fillRate <= 100) {
            setNewFillRate(fillRate)
        } else {
            console.error("Veuillez entrer un nombre entre 0 et 100.");
        }
    }

    const handleUpdateFillRate = async () => {
        try {
            await updateFillRate({
                variables: {
                    newFillRate: newFillRate,
                    unitId: unit.id
                },
                onCompleted: (data) => {
                    setIsEditingFillRate(false)
                    setNewFillRate(newFillRate)
                }
            })
        }
        catch (err) {
            console.error(err)
        }
    }

    let fillColor: string;
    newFillRate > 49 ? fillColor = "rgba(255, 165, 0, 0.5)" : fillColor = "white"
    if (newFillRate == 100) fillColor = "rgba(255, 0, 0, 0.5)";

    return (
        <Paper style={{ padding: 10, minWidth: 250, backgroundColor: fillColor }}>
            <HeadLine>
                <h3 style={{ flex: 1 }}>{unit.packaging.name}</h3>
                <h3 style={{ flex: 1 }}>#{unit.id}</h3>
            </HeadLine>

            {/* Status show/edit */}
            <div>
                {/*Cas où le status est modifiable {updateStatusResponse.loading ? <Loader /> : <Chip label={newStatus} variant="outlined" onClick={() => setIsEditingStatus(!isEditingStatus)} />} */}
                {updateStatusResponse.loading ? <Loader /> : <Chip label={newStatus} variant="outlined" />}
                {isEditingStatus &&
                    <div style={{ position: "absolute", backgroundColor: "white" }}>
                        {getUnitStatusResponse.loading ? <Loader /> :
                            <>
                                {getUnitStatusResponse.data.PackagingUnitStatusValues.map((status: string) => {
                                    return (
                                        <MenuItem value={status} onClick={async () => {
                                            try {
                                                setIsEditingStatus(false)
                                                await updateStatus({
                                                    variables: { newStatus: status, unitId: unit.id },
                                                    onCompleted: () => { setNewStatus(status) }
                                                })
                                            } catch (err) { console.error(err) }

                                        }}>{status}</MenuItem>
                                    )
                                })}
                            </>
                        }
                    </div>
                }
            </div>

            <BottomLine>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <span>Plein à</span>
                    {!isEditingFillRate && <b style={{ marginLeft: 5, marginRight: 5 }}>{newFillRate}%</b>}
                    {isEditingFillRate &&
                        <>
                            <input style={{ marginLeft: 5, maxWidth: 50 }} onChange={handleFillRateUpdate} type="number" min={0} max={100} defaultValue={newFillRate} />
                            <b>%</b>
                            {!updateFillRateResponse.loading && <DoneIcon style={{ cursor: "pointer" }} color={"primary"} onClick={handleUpdateFillRate} />}
                            {updateFillRateResponse.loading && <Loader />}
                        </>
                    }
                    {!isEditingFillRate && <EditIcon color={"primary"} style={{ cursor: "pointer" }} onClick={() => setIsEditingFillRate(true)} />}
                </div>
                {!loading && <Button variant="contained" onClick={handleUnlink}>Retirer</Button>}
                {loading && <Loader />}
                {error && <ErrorMessage>Erreur lors de la suppression...</ErrorMessage>}
            </BottomLine>
        </Paper >
    )
}