import { useEffect, useState } from "react"
import styled from "styled-components"
import { TextField } from "@mui/material"
import { Button } from "@mui/material"
import { loader } from "graphql.macro";
import { useMutation } from '@apollo/client';
import { Paper } from "../../components/Paper"
import { Logo } from "../../components/Logo"
import { Loader } from "../../components/Loader"
import { ErrorMessage } from "../../components/ErrorMessage";

const InsidePaper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width : 350px;
    max-width: 100%;
`

const LoginPageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image:url(../../../assets/bottles.png)
`

const InputsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
`

const ResetPasswordMutation = loader("../../graphql/authentication/mutation/resetPassword.graphql")

export const SetNewPassword = () => {
    const resetToken = new URLSearchParams(window.location.search).get("resetToken")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [formError, setFormError] = useState({
        password: false,
        passwordConfirmation: false
    })
    const [passwordError, setPasswordError] = useState<string | undefined>()
    const [resetPassword, { data, loading, error }] = useMutation(ResetPasswordMutation)
    const [requestError, setRequestError] = useState<string | undefined>()

    useEffect(() => {
        const listener = (event: { code: string; preventDefault: () => void }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleSubmit()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [password, passwordConfirmation]);

    const handleSubmit = async () => {
        if (areFormEmpty()) return setPasswordError("Veuillez remplir tous les champs")
        if (password !== passwordConfirmation) {
            setPasswordError("Les mots de passe ne correspondent pas")
            return
        }
        if (password.length < 8) {
            setPasswordError("Le mot de passe doit contenir au moins 8 caractères")
            return
        }
        setFormError({
            password: false,
            passwordConfirmation: false
        })
        await resetPassword({
            variables: {
                newPassword: password,
                resetToken: resetToken
            },
            onError: (error) => {
                console.error(error)
                setRequestError(error.message)
            }
        })
    }

    const areFormEmpty = () => {
        if (password === "") {
            setFormError({
                password: password === "",
                passwordConfirmation: passwordConfirmation === ""
            })
            return true
        }
        if (passwordConfirmation === "") {
            setFormError({
                password: password === "",
                passwordConfirmation: passwordConfirmation === ""
            })
            return true
        }
        return false
    }

    if (data) {
        window.location.href = "/auth/login"
    }

    return (
        <LoginPageBox>
            <Paper style={{ padding: 50 }}>
                <InsidePaper>
                    <Logo width="100px" />
                    <h1 style={{ textAlign: "center" }}><span style={{ fontSize: 24 }}>Définissez votre mot de passe</span></h1>
                    <p style={{ textAlign: "center" }}>Définissez le nouveau mot de passe de votre compte Swiv</p>
                    {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
                    {requestError && <ErrorMessage>{requestError}</ErrorMessage>}

                    <InputsBox>
                        <TextField
                            label="Mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={(e) => { setPassword(e.target.value) }}
                            error={formError.password || (error !== undefined)}
                            helperText={formError.password && "Renseigner un mot de passe"}
                            value={password}
                        />

                        <TextField
                            label="Répetez le mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                            error={formError.password || (error !== undefined)}
                            helperText={formError.password && "Renseigner un mot de passe"}
                            value={passwordConfirmation}
                        />
                    </InputsBox>

                    <div style={{ height: "30px" }}>
                        {!loading && <Button type="submit" variant="contained" color="primary" onClick={() => {
                            handleSubmit()
                        }}>Définir mon mot de passe</Button>}
                        {loading && <Loader />}
                    </div>
                </InsidePaper>
            </Paper>
        </LoginPageBox>
    )
}