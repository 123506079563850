import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const PACKAGING_ENUMS = loader("../../graphql/packaging/query/packagingEnums.graphql")

export const getPackagingEnums = async () => {
    const response = await apolloClient.query({
        query: PACKAGING_ENUMS,
    })

    return { packagingTypes: response.data.packagingTypes }
}