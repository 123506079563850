import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { rootRouter } from './root-router';
import { theme } from "./Theme/theme"

import { ApolloProvider } from '@apollo/client';
import { apolloClient } from "./api/apolloClient"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={rootRouter} />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);