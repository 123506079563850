import { getSortingCenters } from "../../api/sortingCenter/getSortingCenters";
import { getSortingCenter } from "../../api/sortingCenter/getSortingCenter";


export const sortingCentersLoader = async () => {
    const sortingCenters = await getSortingCenters();
    return sortingCenters;
}

export const sortingCenterLoader = async ({ params }: any) => {
    const sortingCenter = await getSortingCenter(Number(params.id));
    console.log(sortingCenter)
    return sortingCenter;
}