import styled from "styled-components"
const logo: string = require("../assets/logo.svg").default;

interface LogoProps {
    width?: string;
}

let LogoBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

let LogoImg = styled.img`
    max-width: 150px;
    width:120px
`

export const Logo = (props: LogoProps) => {
    return (
        <LogoBox>
            <LogoImg src={logo} alt="logo" style={
                props.width ? { width: props.width } : {}
            } />
        </LogoBox>
    );
}