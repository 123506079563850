import { useEffect, useState } from "react"
import styled from "styled-components"
import { TextField } from "@mui/material"
import { Button } from "@mui/material"
import { loader } from "graphql.macro";
import { useMutation } from '@apollo/client';
import { Paper } from "../../../components/Paper"
import { Logo } from "../../../components/Logo"
import { Loader } from "../../../components/Loader"
import { isEmail } from "../../../data-validator/isEmail"
import { Link } from "react-router-dom";

const loginMutation = loader("../../../graphql/login.mutation.graphql");

const InsidePaper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width : 350px;
    max-width: 100%;
`

const LoginPageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image:url(../../../assets/bottles.png)
`

const InputsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
`


export const Login = () => {
    const [login, { data, error, loading }] = useMutation(loginMutation);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [formError, setFormError] = useState({
        email: false,
        password: false
    })

    useEffect(() => {
        const listener = (event: { code: string; preventDefault: () => void }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log('Enter key was pressed. Run your function.')
                console.log(email, password)
                handleSubmit()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email, password]);

    const handleSubmit = async () => {
        if (areFormEmpty()) return
        if (!isEmail(email)) return

        setFormError({
            email: false,
            password: false
        })

        await login({ variables: { email, password } })
    }

    const areFormEmpty = () => {
        if (email === "" || password === "") {
            setFormError({
                email: email === "",
                password: password === ""
            })
            return true
        }
        return false
    }

    if (data) {
        // wait for jwt to be set and then navigate
        localStorage.setItem("jwt", "Bearer " + data.login.accessToken)
        window.location.href = "/"
    }

    return (
        <LoginPageBox>
            <Paper style={{ padding: 50 }}>
                <InsidePaper>
                    <Logo width="100px" />
                    <h1 style={{ textAlign: "center" }}><span style={{ fontSize: 24 }}>Connexion</span></h1>
                    <p style={{ textAlign: "center" }}>Utiliser votre compte Suivez la Consigne</p>
                    {/* Margin "30px */}

                    <InputsBox>
                        <TextField
                            label="Adresse e-mail"
                            variant="outlined"
                            fullWidth
                            type="email"
                            onChange={(e) => { setEmail(e.target.value) }}
                            error={(!isEmail(email) && email !== "") || (error !== undefined)}
                            helperText={(!isEmail(email) && email !== "" && "Renseigner une adresse e-mail valide") || (error && "Adresse e-mail ou mot de passe incorect")}
                            value={email}
                        />
                        <TextField
                            label="Mot de passe"
                            variant="outlined"
                            fullWidth
                            type="password"
                            onChange={(e) => { setPassword(e.target.value) }}
                            error={formError.password || (error !== undefined)}
                            helperText={formError.password && "Renseigner un mot de passe"}
                            value={password}
                        />
                    </InputsBox>

                    <div style={{ height: "30px" }}>
                        {!loading &&
                            <div style={{ display: "flex", flex: 1, flexDirection: "column", gap: 5 }}>
                                <Link to="/auth/reset-password">Mot de passe oublié ?</Link>
                                <Button type="submit" variant="contained" color="primary" onClick={() => {
                                    handleSubmit()
                                }}>Connexion</Button>
                            </div>
                        }
                        {loading && <Loader />}
                    </div>
                </InsidePaper>
            </Paper>
        </LoginPageBox>
    )
}