import styled from "styled-components"

const ChipWrapper = styled.div`
    background-color:${props => props.color};
    color:white;
    padding-top:3px;
    padding-bottom:3px;
    padding-left:10px;
    padding-right:10px;
    border-radius:30px;
`

export const Chip = ({ color, children }: any) => {
    return (
        <ChipWrapper color={color}><p>{children}</p></ChipWrapper>
    )
}