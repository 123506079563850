import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const SORTING_CENTER = loader("../../graphql/admin/sortingCenter/query/sortingCenter.graphql");

export const getSortingCenter = async (id: number) => {
    const { data } = await apolloClient.query({
        query: SORTING_CENTER,
        variables: {
            sortingCenterId: id
        }
    })
    return { sortingCenter: data.sortingCenter }
}