import React, { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { InputLabel, TextField } from '@mui/material';

import { Loader } from './Loader';

import styled from "styled-components"
import { Place } from '../types/Place';

export const SuggestionContainer = styled.div`
    padding:20px;
    cursor:pointer;
    border: 1px solid #ccc;
    &:hover {
    background-color: #f0f0f0;
  }
`

export const AddressAutoComplete = (props: any) => {
    const [address, setAddress] = useState(props.defaultValue);

    const handleSelect = async (selectedAddress: any) => {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);

        // Extraire les champs nécessaires
        const { formatted_address, address_components } = results[0];
        // Trouver le numéro de rue et le nom de la rue dans address_components
        let streetNumber = '';
        let streetName = '';

        address_components.forEach((component) => {
            if (component.types.includes('street_number')) {
                streetNumber = component.long_name;
            } else if (component.types.includes('route')) {
                streetName = component.long_name;
            }
        });

        const city = address_components.find((component) =>
            component.types.includes('locality')
        )?.long_name || '';
        const zipCode = address_components.find((component) =>
            component.types.includes('postal_code')
        )?.long_name || '';

        // Stocker les valeurs dans la variable selectedPlace
        const newSelectedPlace: Place = {
            address: formatted_address,
            city,
            zipCode,
            latitude: latLng.lat,
            longitude: latLng.lng,
        };

        props.setSelectedPlace(newSelectedPlace);
        // Mettre à jour la valeur du champ d'adresse
        setAddress(newSelectedPlace.address);
    };

    console.log('default', props.defaultValue)

    return (
        <div>
            <PlacesAutocomplete
                value={address}
                onChange={(value) => setAddress(value)}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <InputLabel>Adresse</InputLabel>
                        <TextField
                            {...getInputProps({})}
                            id="address"
                            label="Adresse"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                        />
                        <div>
                            {loading && <Loader />}
                            {suggestions.map((suggestion) => (
                                <SuggestionContainer {...getSuggestionItemProps(suggestion)} key={suggestion.id}>
                                    {suggestion.description}
                                </SuggestionContainer>
                            ))}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>

    );
};