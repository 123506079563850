import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const COLLECT_POINTS = loader("../../graphql/collectPoint/query/collectPoints.graphql");

export const getCollectPoints = async () => {
    const { data } = await apolloClient.query({
        query: COLLECT_POINTS,
    })

    return { collectPoints: data.collectPoints }
}