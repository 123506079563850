import { useState, useEffect } from "react"
import { Button, TextField } from "@mui/material"
import { BackButtonAndTitle } from "../../../components/BackButtonAndTitle"
import { Container } from "../../../components/Container"
import { SuggestionContainer } from "../../../components/AddressAutoComplete"
import styled from "styled-components"
import { Loader } from "../../../components/Loader"
import { loader } from "graphql.macro"
import { apolloClient } from "../../../api/apolloClient"
import DeleteIcon from '@mui/icons-material/Delete';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import { ErrorMessage } from "../../../components/ErrorMessage"

const AutoCompleteContainer = styled.div`
    max-width:300px;
    padding-top:15px
`

const SelectedUnitContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:10px;
    max-width:300px;
    margin-top: 10px;
    margin-bottom:20px
`

const SelectedUnit = styled.div`
    padding:5px;
    border:solid 1px #ccc;
    border-radius:4px;
    display:flex;
    align-items:center;
    gap:5px
`

const SORTING_CENTERS = loader('../../../graphql/sortingCenter/query/sortingCenters.graphql');
const INVITE_SORTING_CENTERS_TO_COLLECT_POINT = loader('../../../graphql/sortingCenter/mutation/inviteSortingCentersToCollectPoint.graphql');

export const InviteSortingCenter = ({ params }: any) => {
    const navigate = useNavigate()

    let { id } = useParams()
    const [inputValue, setInputValue] = useState("")
    const [loadingQuery, setLoadingQuery] = useState(false);
    const [fetchedCenters, setFetchedCenters] = useState<any[]>([])
    const [error404, setError404] = useState(false)
    const [selectedCenters, setSelectedCenters] = useState<any[]>([])

    let [inviteCentersMutation, { loading, error }] = useMutation(INVITE_SORTING_CENTERS_TO_COLLECT_POINT);

    useEffect(() => {
        // Fonction de délai (debounce) pour retarder l'exécution de la requête
        if (inputValue.trim() !== '') {
            setLoadingQuery(true)
        }

        const delaySearch = setTimeout(async () => {
            if (inputValue.trim() !== '') {
                setError404(false)
                try {
                    setLoadingQuery(true);
                    console.log("loading. . .")
                    const response = await apolloClient.query({
                        query: SORTING_CENTERS,
                        variables: {
                            name: inputValue
                        }
                    })
                    if (response.data) {
                        console.log(response.data)
                        setLoadingQuery(false);
                        setFetchedCenters(response.data.sortingCenters)
                    }
                } catch (error) {
                    setError404(true)
                    setLoadingQuery(false);
                    setFetchedCenters([])
                }


            }
        }, 800);

        // Effacer le délai précédent lorsqu'une nouvelle saisie est effectuée
        return () => {
            setFetchedCenters([])
            setError404(false)
            clearTimeout(delaySearch)
        };
    }, [inputValue]);

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value)
    }

    const handleSelectCenter = (center: any) => {
        // check if unit is already in
        const centerIndex = selectedCenters.findIndex(c => c.id === center.id)
        if (centerIndex === -1) {
            setSelectedCenters(prev => [...prev, center])
        }

        // Reset search barƒ
        setFetchedCenters([])
        setInputValue("")
    }

    const handleUnselectCenter = (center: any) => {
        const unitToRemoveIndex = selectedCenters.findIndex(c => c.id === center.id)
        if (unitToRemoveIndex !== -1) {
            const updatedSelectedUnits = [
                ...selectedCenters.slice(0, unitToRemoveIndex),
                ...selectedCenters.slice(unitToRemoveIndex + 1),
            ];

            // Mettre à jour l'état avec la nouvelle liste
            setSelectedCenters(updatedSelectedUnits);
        }
    }

    const handleInviteCentersToCollectPoint = async () => {
        try {
            await inviteCentersMutation({
                variables: {
                    centersIds: selectedCenters.map(c => String(c.id)),
                    collectPointId: Number(id)
                }
            })
            navigate("/points-de-collecte/" + id)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container>
            <BackButtonAndTitle title="Inviter un centre de tri" />

            <AutoCompleteContainer>
                <TextField value={inputValue} placeholder="Nom du centre de tri à inviter" fullWidth onChange={handleInputChange} />
                {loadingQuery && <div style={{ marginTop: 10 }}><Loader /></div>}
                {error404 && <p>Pas d'unité correspondante à cet ID</p>}
                {(!loadingQuery && fetchedCenters.length > 0) &&
                    fetchedCenters.map(center => {
                        return (
                            <SuggestionContainer onClick={() => handleSelectCenter(center)}>
                                <span>{center.name}</span>
                            </SuggestionContainer>
                        )
                    })
                }
            </AutoCompleteContainer>

            <SelectedUnitContainer>
                {selectedCenters.map(center => {
                    return (
                        <SelectedUnit>
                            <p>{center.name}</p>
                            <Button onClick={() => handleUnselectCenter(center)}><DeleteIcon /></Button>
                        </SelectedUnit>
                    )
                })}
            </SelectedUnitContainer>

            {selectedCenters.length > 0 && <Button variant="contained" onClick={handleInviteCentersToCollectPoint}>Sauvegarder</Button>}
            {selectedCenters.length > 0 && loading && <Loader />}
            {error && <ErrorMessage>Une erreur s'est produite...</ErrorMessage>}
        </Container >
    )
}