import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const SORTING_CENTERS = loader("../../graphql/admin/sortingCenter/query/sortingCenters.graphql");

export const getSortingCenters = async () => {
    const { data } = await apolloClient.query({
        query: SORTING_CENTERS,
    })
    return { sortingCenters: data.sortingCenters }
}