import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const PRODUCT_TYPES = loader("../../graphql/product/query/productTypes.graphql")

export const getProductTypes = async () => {
    const response = await apolloClient.query({
        query: PRODUCT_TYPES,
    })

    return { productTypes: response.data.productTypes }
}