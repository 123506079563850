import { useLoaderData, useNavigate } from "react-router-dom"
import { Button, InputLabel, MenuItem, Select, TextField, useScrollTrigger, Autocomplete, OutlinedInput, Switch } from "@mui/material"
import { DeleteButton } from "../../../components/DeleteButton";
import { useMutation } from "@apollo/client";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { loader } from 'graphql.macro';

import { Loader } from "../../../components/Loader";
import { ErrorMessage } from "../../../components/ErrorMessage";
import styled from "styled-components";
import { useState } from "react";

const Form = styled.form`
    display:grid;   
    gap:10px;
    max-width: 500px;
    padding:10px;
`

const ButtonRow = styled.div`
    display:flex;
    flex-direction:row;
    align-content:center;
`

const UPDATE_PRODUCT = loader("../../../graphql/product/mutation/updateProduct.graphql")
const DELETE_PRODUCT = loader("../../../graphql/product/mutation/deleteProduct.graphql")

export const Product = () => {
    const data = useLoaderData() as any;
    const product = data.product
    const companies = data.companies
    const productTypes = data.productTypes
    const packagings = data.packagings

    const navigate = useNavigate()
    const [updateProduct, { loading, error }] = useMutation(UPDATE_PRODUCT)
    const [deleteProduct, { loading: loadingDelete, error: errorDelete }] = useMutation(DELETE_PRODUCT)

    const [isRecommended, setIsRecommended] = useState(product.isRecommended)

    const formik = useFormik({
        initialValues: {
            updateProductId: product.id,
            name: product.name,
            companyId: product.company.id,
            type: product.type,
            packagingId: product.packaging.id,
            ean: product.EAN,
            deposit: product.deposit,
            imageUrl: product.image,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            companyId: Yup.number().required('Champ requis'),
            type: Yup.string().required('Champ requis'),
            packagingId: Yup.number().required('Champ requis'),
            ean: Yup.string().required('Champ requis'),
            deposit: Yup.number().min(0, "la consigne doit être supérieur ou égale à 0").required('Champ requis'),
            imageUrl: Yup.string(),
        }),
        onSubmit: async (values: any) => {
            updateProduct({
                variables: {
                    ...values,
                    ...{
                        deposit: Number(values.deposit),
                        isRecommended: isRecommended
                    }
                },
                onCompleted: () => {
                    navigate('/produits')
                }
            })
        }
    })

    return (
        <Form onSubmit={
            formik.handleSubmit
        }>
            <h2>{product.name}</h2>
            <div>
                <InputLabel>Nom *</InputLabel>
                <TextField
                    id="name"
                    placeholder="Nom"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && formik.errors.name ? true : false}
                />
            </div>


            <div>
                <InputLabel>Entreprise *</InputLabel>
                <Autocomplete
                    id="company"
                    options={companies}
                    getOptionLabel={(company: any) => company.name}
                    value={product.company}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder="Entreprise"
                                variant="outlined"
                                error={formik.touched.companyId && formik.errors.companyId ? true : false}
                            />
                        )
                    }}
                    onChange={(event, value) => {
                        formik.setFieldValue('companyId', value.id)
                    }}
                />
            </div>

            <div>
                <InputLabel>Type *</InputLabel>
                <Autocomplete
                    id="type"
                    options={productTypes}
                    getOptionLabel={(productType: any) => productType}
                    value={product.type}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Type"
                            variant="outlined"
                            error={formik.touched.type && formik.errors.type ? true : false}
                        />}
                    onChange={(event, value) => {
                        formik.setFieldValue('type', value)
                    }}
                />
            </div>


            <div>
                <InputLabel>Emballage *</InputLabel>
                <Autocomplete
                    id="packaging"
                    options={packagings}
                    getOptionLabel={(packaging: any) => packaging.name}
                    value={product.packaging}
                    renderInput={(params) => <TextField
                        {...params}
                        placeholder="Emballage"
                        variant="outlined"
                        error={formik.touched.packagingId && formik.errors.packagingId ? true : false}
                    />
                    }
                    onChange={(event, value) => {
                        formik.setFieldValue('packagingId', value.id)
                    }}
                />
            </div>

            <div>
                <InputLabel>EAN * (sans espace)</InputLabel>
                <TextField
                    id="EAN"
                    placeholder="EAN"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps('ean')}
                    error={formik.touched.ean && formik.errors.ean ? true : false}
                />
            </div>

            <div>
                <InputLabel>Consigne (€) *</InputLabel>
                <OutlinedInput
                    id="deposit"
                    placeholder="Consigne"
                    fullWidth
                    startAdornment="€"
                    type="number"
                    {...formik.getFieldProps('deposit')}
                    error={formik.touched.deposit && formik.errors.deposit ? true : false}
                />
            </div>

            <div>
                <InputLabel>url de l'image (optionnel)</InputLabel>
                <TextField
                    id="imageUrl"
                    placeholder="url de l'image"
                    variant="outlined"
                    fullWidth
                    {...formik.getFieldProps('imageUrl')}
                    error={formik.touched.imageUrl && formik.errors.imageUrl ? true : false}
                />
            </div>

            <div style={{ display: "flex", alignItems: 'center' }}>
                <InputLabel>Produit Recommandé </InputLabel>
                <Switch
                    checked={isRecommended}
                    onChange={() => setIsRecommended(!isRecommended)}
                />
            </div>

            {(!loading && !loadingDelete) &&
                <ButtonRow>
                    <Button variant="contained" style={{ marginRight: 15 }} type="submit">Sauvegarder</Button>
                    <DeleteButton onClick={() => {
                        deleteProduct({
                            variables: {
                                deleteProductId: product.id
                            },
                            onCompleted: () => {
                                navigate('/produits')
                            }
                        })
                        navigate('/produits')
                    }}>Supprimer</DeleteButton>
                </ButtonRow>
            }

            {(loading || loadingDelete) && <Loader />}
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            {errorDelete && <ErrorMessage>{errorDelete.message}</ErrorMessage>}
        </Form>
    )
}