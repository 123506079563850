import { Props } from "../types/Props";

import styled from "styled-components";
// Box should take 100% of the height of the browser window, without scroll bar
let Box = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    maw-width: 100%;
    flex:1;
`

// the root box of the app
export const RootBox = ({ children }: Props) => {
    return (
        <Box>
            {children}
        </Box>
    )
}