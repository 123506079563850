import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const PRODUCT = loader("../../graphql/product/query/product.graphql")

export const getProductByEAN = async (EAN: string) => {
    const response = await apolloClient.query({
        query: PRODUCT,
        variables: {
            ean: EAN
        }
    })

    return { product: response.data.product }
}

export const getProductById = async (id: number) => {
    const response = await apolloClient.query({
        query: PRODUCT,
        variables: {
            productId: id
        }
    })

    return { product: response.data.product }
}