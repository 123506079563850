import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const PACKAGINGS = loader("../../graphql/packaging/query/packagings.graphql")
const PACKAGINGS_NAME = loader("../../graphql/packaging/query/packagingsName.graphql")

export const getPackagings = async () => {
    const response = await apolloClient.query({
        query: PACKAGINGS,
    })

    return { packagings: response.data.packagings }
}

export const getPackagingsName = async () => {
    const response = await apolloClient.query({
        query: PACKAGINGS_NAME,
    })
    return { packagings: response.data.packagings }
}