import { getCompaniesName } from "../../api/company/getCompanies";
import { getPackagingsName } from "../../api/packaging/getPackagings";
import { getProductTypes } from "../../api/product/getProducts";
import { getCompaniesProducts } from "../../api/company/getCompanies";
import { getProductByEAN, getProductById } from "../../api/product/getProduct";

export const productsLoader = async () => {
    const companies = await getCompaniesName()
    const packagings = await getPackagingsName()
    const productTypes = await getProductTypes()
    const companiesProducts = await getCompaniesProducts()
    return {
        ...companiesProducts,
        ...companies,
        ...packagings,
        ...productTypes
    }
}

export const productLoader = async ({ params }: any) => {
    const companies = await getCompaniesName()
    const packagings = await getPackagingsName()
    const productTypes = await getProductTypes()
    const product = await getProductById(Number(params.id))
    return {
        ...companies,
        ...packagings,
        ...productTypes,
        ...product
    }
}