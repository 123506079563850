import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0d3396',
        }
    },
    typography: {
        button: {
            textTransform: 'none',
            fontWeight: 500,
            fontSize: '1rem',
        }
    },
})

