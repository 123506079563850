import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const COMPANIES = loader("../../graphql/company/query/companies.graphql");
const COMPANIES_NAME = loader("../../graphql/company/query/companiesName.graphql")
const COMPANIES_PRODUCTS = loader("../../graphql/company/query/companiesProducts.graphql")

export const getCompanies = async () => {
    const { data } = await apolloClient.query({
        query: COMPANIES,
    })

    return { companies: data.companies }
}

export const getCompaniesName = async () => {
    const { data } = await apolloClient.query({
        query: COMPANIES_NAME,
    })

    return { companies: data.companies }
}

export const getCompaniesProducts = async () => {
    const { data } = await apolloClient.query({
        query: COMPANIES_PRODUCTS,
    })

    return { companiesProducts: data.companiesProducts }
}