import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const renderHeader = (params: any) => {
    return (
        <b>
            {params.colDef.headerName}
        </b >
    )
}

const columns = [
    {
        field: 'name',
        flex: 1,
        headerName: 'Nom',
        renderHeader
    },
    {
        field: "level",
        flex: 1,
        headerName: 'Niveau',
        renderHeader
    },
    {
        field: "type",
        flex: 1,
        headerName: 'Type',
        renderHeader
    }
]

export const PackagingsTable = (props: any) => {
    const navigate = useNavigate()
    return (
        <>
            <DataGrid
                columns={columns}
                rows={props.packagings}
                onRowClick={(e) => {
                    console.log('row clicked', e.id)
                    navigate(`/emballages/${e.id}`)
                }}
                sx={{
                    // disable cell selection style
                    '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                    },
                    // pointer cursor on ALL rows
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                    },
                }}
            />
        </>)
}