import { useLoaderData, useNavigate } from "react-router-dom"
import { Container } from "../../../components/Container"
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { loader } from 'graphql.macro';
import { Button, InputLabel, Switch, TextField } from "@mui/material";
import { Loader } from "../../../components/Loader";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { DeleteButton } from "../../../components/DeleteButton";
import styled from "styled-components";
import { useState } from "react";

const EDIT_COMPANY = loader("../../../graphql/company/mutations/updateCompany.graphql");
const DELETE_COMPANIES = loader("../../../graphql/company/mutations/deleteCompanies.graphql");

const Form = styled.form`
    display:grid;   
    gap:10px;
    max-width: 500px;
    padding:10px;
`

export const Company = (props: any) => {
    const { company } = useLoaderData() as any;
    console.log('company', company)
    let [isCollectPoint, setIsCollectPoint] = useState(company.isCollectPoint as boolean);

    let [updateCompany, { data, loading, error }] = useMutation(EDIT_COMPANY);
    let [deleteCompanies, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_COMPANIES);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: company.name as string,
            email: company.email as string,
            phone: company.phone as string,
            address: company.address as string,
            zipCode: company.zipCode as string,
            city: company.city as string,
            latitude: company.latitude as number,
            longitude: company.longitude as number,
            isCollectePoint: company.isCollectePoint as boolean,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Le nom de l\'entreprise est obligatoire'),
            email: Yup.string().email('Email invalide').required('L\'email est obligatoire'),
            phone: Yup.string().required('Le téléphone est obligatoire'),
            address: Yup.string().required('L\'adresse est obligatoire'),
            zipCode: Yup.string().required('Le code postal est obligatoire'),
            city: Yup.string().required('La ville est obligatoire')
        }),
        onSubmit: values => {
            values.latitude = Number(values.latitude)
            values.longitude = Number(values.longitude)
            updateCompany({
                variables: { ...{ updateCompanyId: company.id }, ...values, ...{ isCollectPoint } },
                onCompleted: () => navigate("/entreprises")
            })
        },
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <h2>{company.name}</h2>
            <TextField
                id="name"
                label="Nom de l'entreprise"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('name')}
                error={formik.touched.name && formik.errors.name ? true : false}
                helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('email')}
                error={formik.touched.email && formik.errors.email ? true : false}
                helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
                id="phone"
                label="Téléphone"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('phone')}
                error={formik.touched.phone && formik.errors.phone ? true : false}
                helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
                id="address"
                label="Adresse"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('address')}
                error={formik.touched.address && formik.errors.address ? true : false}
                helperText={formik.touched.address && formik.errors.address}
            />
            <TextField
                id="zipCode"
                label="Code postal"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('zipCode')}
                error={formik.touched.zipCode && formik.errors.zipCode ? true : false}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
            <TextField
                id="city"
                label="Ville"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('city')}
                error={formik.touched.city && formik.errors.city ? true : false}
                helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
                id="latitude"
                label="Latitude"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('latitude')}
                error={formik.touched.latitude && formik.errors.latitude ? true : false}
                helperText={formik.touched.latitude && formik.errors.latitude}
            />
            <TextField
                id="longitude"
                label="Longitude"
                variant="outlined"
                fullWidth
                margin="dense"
                {...formik.getFieldProps('longitude')}
                error={formik.touched.longitude && formik.errors.longitude ? true : false}
                helperText={formik.touched.longitude && formik.errors.longitude}
            />
            <div style={{ display: "flex", alignItems: 'center' }}>
                <InputLabel>Point de collecte </InputLabel>
                <Switch
                    checked={isCollectPoint}
                    onChange={() => setIsCollectPoint(!isCollectPoint)}
                />
            </div>
            {(!loading && !deleteLoading) &&
                <div style={{ marginTop: 20 }}>
                    <Button variant="contained" style={{ marginRight: 20 }} type="submit">Modifier l'entreprise</Button>
                    <DeleteButton
                        onClick={() => {
                            deleteCompanies({
                                variables: { ids: [company.id] },
                                onCompleted: () => navigate("/entreprises")
                            })
                        }}
                    >Supprimer</DeleteButton>
                </div>
            }
            {(loading || deleteLoading) && <Loader />}
            {error && <ErrorMessage>Une erreur est survenue...</ErrorMessage>}
            {deleteError && <ErrorMessage>Vous ne pouvez pas supprimer une entreprise ayant des produits référencés</ErrorMessage>}
        </Form>
    )
}