import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import { Root } from "./pages/Root";
import { Home } from "./pages/home/Home";
import { homeLoader } from "./loaders/home/Home";

import { Packagings } from "./pages/packagings/Packagings"
import { Packaging } from "./pages/packagings/packaging/Packaging";
import { CreatePackagingUnits } from "./pages/packagings/packaging/CreatePackagingUnits";
import { GenerateUnitQRCodes } from "./pages/packagings/packaging/GenerateUnitQRCodes"
import { packagingLoader, packagingsLoader } from "./loaders/packaging/Packagings";

import { Products } from "./pages/products/Products";
import { productLoader, productsLoader } from "./loaders/product/Product";

import { Companies } from "./pages/companies/Companies";
import { companiesLoader } from "./loaders/company/companies";
import { Company } from "./pages/companies/company/Company";
import { companyLoader } from "./loaders/company/companies";

import { CollectPoints } from './pages/collect-points/CollectPoints'
import { CreateCollectPoint } from './pages/collect-points/CreateCollectPoint'
import { UpdateCollectPoint } from './pages/collect-points/collect-point/UpdateCollectPoint'
import { collectPointsLoader, collectPointDetailsLoader } from "./loaders/collectPoint/collectPoints";
import { CollectPoint } from "./pages/collect-points/collect-point/CollectPoint";
import { AssignPackagingUnits } from "./pages/collect-points/collect-point/AssignPackagingUnits";

import { Login } from "./pages/authentication/Login/Login.page";
import { ResetPassword } from "./pages/authentication/ResetPassword";

import { PackagingUnitScannedPage } from "./pages/scan/PackagingUnitScannedPage";

import { ErrorPage } from "./pages/errors/ErrorPage";
import { Product } from "./pages/products/product/Product";


import { AdminRoot } from "./pages/admin/AdminRoot";
import { AdminHome } from "./pages/admin/Home";
import { SortingCenters } from "./pages/admin/sorting-centers/SortingCenters";
import { sortingCenterLoader, sortingCentersLoader } from "./loaders/sortingCenter/sortingCenter";
import { CreateSortingCenter } from "./pages/admin/sorting-centers/CreateSortingCenter";
import { SortingCenter } from "./pages/admin/sorting-centers/SortingCenter";
import { AttachUserToSortingCenter } from "./pages/admin/sorting-centers/AttachUserToSortingCenter";
import { InviteSortingCenter } from "./pages/collect-points/collect-point/InviteSortingCenter";
import { SetNewPassword } from "./pages/authentication/SetNewPassord";
import { AssignPackagingUnitsToCenter } from "./pages/admin/sorting-centers/AssignPackagingUnitsToCenter";


export const rootRouter = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <CollectPoints />,
                loader: collectPointsLoader
            },
            {
                path: "/emballages",
                element: <Packagings />,
                loader: packagingsLoader,
            },
            {
                path: "/emballages/:id",
                element: <Packaging />,
                loader: packagingLoader,
            },
            {
                path: "/emballages/:id/nouvelles-unites",
                element: <CreatePackagingUnits />
            },
            {
                path: "/emballages/:id/generer-qr-codes",
                element: <GenerateUnitQRCodes />
            },
            {
                path: "/produits",
                element: <Products />,
                loader: productsLoader,
            },
            {
                path: "/produits/:id",
                element: <Product />,
                loader: productLoader,
            },
            {
                path: "/entreprises",
                element: <Companies />,
                loader: companiesLoader,
            },
            {
                path: "/entreprises/:id",
                element: <Company />,
                loader: companyLoader,
            },
            // {
            //     path: "/points-de-collecte",
            //     element: <CollectPoints />,
            //     loader: collectPointsLoader
            // },
            {
                path: "/points-de-collecte/:id",
                element: <CollectPoint />,
                loader: collectPointDetailsLoader
            },
            {
                path: "/points-de-collecte/:id/modifier",
                element: <UpdateCollectPoint />,
                loader: collectPointDetailsLoader
            },
            {
                path: "/points-de-collecte/:id/assigner-nouvelles-unites/",
                element: <AssignPackagingUnits />
            },
            {
                path: "/points-de-collecte/:id/inviter-centre-de-tri/",
                element: <InviteSortingCenter />
            },
            {
                path: "/points-de-collecte/nouveau",
                element: <CreateCollectPoint />
            },
        ]
    },
    {
        path: "/admin",
        element: <AdminRoot />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/admin/",
                element: <AdminHome />
            },
            {
                path: "/admin/centres-de-tri",
                element: <SortingCenters />,
                loader: sortingCentersLoader
            },
            {
                path: "/admin/centres-de-tri/:id",
                element: <SortingCenter />,
                loader: sortingCenterLoader
            },
            {
                path: "/admin/centres-de-tri/:id/nouvel-utilisateur",
                element: <AttachUserToSortingCenter />
            }
            ,
            {
                path: "/admin/centres-de-tri/:id/lier-unite-emballage",
                element: <AssignPackagingUnitsToCenter />
            },
            {
                path: "/admin/centres-de-tri/nouveau",
                element: <CreateSortingCenter />
            },
        ]
    },
    {
        path: "/auth",
        children: [
            {
                path: "/auth/login",
                element: <Login />,
            },
            {
                path: "/auth/reset-password",
                element: <ResetPassword />,
            },
            {
                path: "/auth/set-new-password",
                element: <SetNewPassword />,
            }
        ]
    },
    {
        path: "/public",
        children: [
            {
                path: "/public/unit-scanned/:cryptedId",
                element: <PackagingUnitScannedPage />
            }
        ]
    }
]);