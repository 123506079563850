import styled from "styled-components";
import { Button } from "@mui/material";
import { Link, useLoaderData } from "react-router-dom";
import { CollectPointMap } from "../../components/collectPoint/CollectPointMap";

import { CollectPointListElement } from "./component/CollectPointListElement";

const FullBrowserContainer = styled.div`
    display:flex;
    flex:1;
    overflow: hidden;
    max-height:90vh;
`

/**
 * CollectPoint list
 */
const OneThirdLeftContainer = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    border-right: 1px solid #D3D3D3; /* Bordure à droite */
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1); /* Ombre légère à droite */
`

const ButtonWrapper = styled.div`
    width:100%;
    padding:10px
`

const ListWrapper = styled.div`
    flex:1;
    overflow-x: hidden;
    overflow-y: scroll;
`

/**
 * Map Container
 */
const TwoThirdLeftContainer = styled.div`
    flex:2;
`

export const CollectPoints = () => {
    const { collectPoints } = useLoaderData() as any;
    console.log(collectPoints)

    return (
        <FullBrowserContainer>
            <OneThirdLeftContainer>
                <ButtonWrapper>
                    <Link to="/points-de-collecte/nouveau">
                        <Button variant="contained">+ Point de collecte</Button>
                    </Link>
                </ButtonWrapper>
                <ListWrapper>
                    {collectPoints.map((collectPoint: any
                    ) => {
                        return <CollectPointListElement collectPoint={collectPoint} key={collectPoint.id} />
                    })}
                </ListWrapper>
            </OneThirdLeftContainer>

            <TwoThirdLeftContainer>
                <CollectPointMap collectPoints={collectPoints} />
            </TwoThirdLeftContainer>
        </FullBrowserContainer>
    )
}