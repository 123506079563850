import { DataGrid, GridRowId } from '@mui/x-data-grid';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Loader } from '../../components/Loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Fragment, useState } from 'react';
import { ProductsInCollapseTable } from './ProductsInCollapseTable';

const Row = (props: { row: any }) => {
    const { row } = props;
    const [open, setOpen] = useState(true);
    return (
        <>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <b>{row.name}</b>
            <Collapse in={open} sx={{ width: "100%" }}>
                <ProductsInCollapseTable rows={row.products} />
            </Collapse>
        </>
    )
}

export const ProductsTable = (props: any) => {
    const navigate = useNavigate()

    // order rows by name
    props.rows.sort((a: any, b: any) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    })

    return (
        <div style={{ width: "100%" }}>
            <TableContainer>
                <Table>
                    <TableBody>
                        {props.rows.map((row: any) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}