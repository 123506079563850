import styled from "styled-components"

const ErrorMessageP = styled.p`
    color: red;
    font-size: 0.8rem;
    margin: 0;
    font-weight: 600;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid red;
`

export const ErrorMessage = (props: any) => {
    return (
        <ErrorMessageP>{props.children}</ErrorMessageP>
    )
}