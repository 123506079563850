import { Link, useLoaderData } from "react-router-dom";
import { Container } from "../../../components/Container";
import { Button } from "@mui/material";

function groupPackagingUnitsByPackaging(packagingUnits: any[]) {
    return packagingUnits.reduce((acc, unit) => {
        const packagingName = unit.packaging.name;
        if (!acc[packagingName]) {
            acc[packagingName] = [];
        }
        acc[packagingName].push(unit);
        return acc;
    }, {});
}

export const SortingCenter = () => {
    const { sortingCenter } = useLoaderData() as any;
    groupPackagingUnitsByPackaging(sortingCenter.packagingUnits)
    return (
        <Container>
            <h1>{sortingCenter.name}</h1>
            <Link to={`/admin/centres-de-tri/${sortingCenter.id}/nouvel-utilisateur`} style={{ marginRight: 10 }}>
                <Button variant="contained">+ Utilisateur</Button>
            </Link>
            <Link to={`/admin/centres-de-tri/${sortingCenter.id}/lier-unite-emballage`}>
                <Button variant="outlined">Lier unité d'emballage</Button>
            </Link>
            <Container>
                <div>
                    <h2>Utilisateurs</h2>
                    <ul style={{ paddingLeft: 20 }}>
                        {sortingCenter.users && sortingCenter.users.map((user: any) => <li key={user.id}>{user.email}</li>)}
                    </ul>
                </div>

                <div>
                </div>


            </Container>
        </Container>
    )
}