import { useEffect, useState } from "react"
import styled from "styled-components"
import { TextField } from "@mui/material"
import { Button } from "@mui/material"
import { loader } from "graphql.macro";
import { useMutation } from '@apollo/client';
import { Paper } from "../../components/Paper"
import { Logo } from "../../components/Logo"
import { Loader } from "../../components/Loader"
import { isEmail } from "../../data-validator/isEmail"
import { ErrorMessage } from "../../components/ErrorMessage";
import { SuccessMessage } from "../../components/SuccessMessage";
import { BackButtonAndTitle } from "../../components/BackButtonAndTitle";


const InsidePaper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width : 350px;
    max-width: 100%;
`

const LoginPageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image:url(../../../assets/bottles.png)
`

const InputsBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
`

const ResetPasswordRequestMutation = loader("../../graphql/authentication/mutation/resetPasswordRequest.graphql")


export const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const [, setFormError] = useState({
        email: false,
    })
    const [success, setSuccess] = useState(false)
    const [resetPasswordRequest, { loading, error }] = useMutation(ResetPasswordRequestMutation)

    useEffect(() => {
        const listener = (event: { code: string; preventDefault: () => void }) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handleSubmit()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [email]);

    const handleSubmit = async () => {
        if (areFormEmpty()) return
        if (!isEmail(email)) return

        setFormError({
            email: false
        })
        await resetPasswordRequest({
            variables: {
                email
            },
            onCompleted: (data) => {
                console.log(data)
                if (data.ResetPasswordRequest) {
                    setSuccess(true)
                }
            },
            onError: (error) => {
                console.error(error)
            }
        })
    }

    const areFormEmpty = () => {
        if (email === "") {
            setFormError({
                email: email === ""
            })
            return true
        }
        return false
    }

    // if (data) {
    //     // wait for jwt to be set and then navigate
    //     localStorage.setItem("jwt", "Bearer " + data.login.accessToken)
    //     window.location.href = "/"
    // }

    return (
        <LoginPageBox>
            <Paper style={{ padding: 50 }}>
                <InsidePaper>
                    <Logo width="100px" />
                    <BackButtonAndTitle title="Chercher mon compte"/>
                    <p style={{ textAlign: "center" }}>Entrez votre adresse email pour demander à changer votre mot de passe</p>
                    {error && <ErrorMessage>Cette adresse email n'est associée à aucun compte</ErrorMessage>}
                    {success && <SuccessMessage>Un email vous a été envoyé pour réinitialiser votre mot de passe</SuccessMessage>}
                    <InputsBox>
                        <TextField
                            label="Adresse e-mail"
                            variant="outlined"
                            fullWidth
                            type="email"
                            onChange={(e) => { setEmail(e.target.value) }}
                            error={(!isEmail(email) && email !== "")}
                            helperText={(!isEmail(email) && email !== "" && "Renseigner une adresse e-mail valide")}
                            value={email}
                        />
                    </InputsBox>

                    <div style={{ height: "30px" }}>
                        {!loading && <Button type="submit" variant="contained" color="primary" onClick={() => {
                            handleSubmit()
                        }}>Chercher</Button>}
                        {loading && <Loader />}
                    </div>
                </InsidePaper>
            </Paper>
        </LoginPageBox>
    )
}