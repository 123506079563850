import { getPackaging } from "../../api/packaging/getPackaging"
import { getPackagingEnums } from "../../api/packaging/getPackagingEnums"
import { getPackagings } from "../../api/packaging/getPackagings"

export const packagingsLoader = async () => {
    const enums = await getPackagingEnums()
    const packagings = await getPackagings()
    return { ...enums, ...packagings }

}

export const packagingLoader = async ({ params }: any) => {
    const enums = await getPackagingEnums()
    const packaging = await getPackaging(Number(params.id))

    return { ...enums, ...packaging }
}
