import styled from "styled-components"
import { Link } from "react-router-dom"
import { computeCollectPointFillRate } from "../../../utils/computeCollectPointFillRate"

const ListElementWrapper = styled.div`
    padding:20px;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    cursor : pointer
`

export const CollectPointListElement = (props: any) => {
    const collectPoint = props.collectPoint
    return (
        <Link to={`/points-de-collecte/${collectPoint.id}`} style={{ textDecoration: "none", color: 'black' }}>
            <ListElementWrapper>
                <h3>{collectPoint.name}</h3>
                <ul>
                    <li><span><b>Caisses de collecte</b> : {collectPoint.storageCrates.length}</span></li>
                    <li><span><b>Taux de remplissage</b> : {computeCollectPointFillRate(collectPoint)}%</span></li>
                </ul>
            </ListElementWrapper>
        </Link>
    )
}