import { useState } from 'react';
import { ButtonLine, ButtonsAndTable, TableContainer } from '../../components/grid/ButtonsAndTable';
import { Button, IconButton, Typography } from '@mui/material';

import { useLoaderData } from 'react-router-dom';
import { CreateProductModal } from './CreateProductModal';

import { ProductsTable } from './ProductsTable';

export const Products = () => {
    const [openModal, setOpenModal] = useState(false)
    const { companiesProducts } = useLoaderData() as any;

    return (
        <>
            <CreateProductModal openModal={openModal} setOpenModal={setOpenModal} />
            <ButtonsAndTable>
                <ButtonLine>
                    <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={() => setOpenModal(true)}>
                        Créer un Produit
                    </Button>
                </ButtonLine>
                <TableContainer>
                    <ProductsTable rows={companiesProducts} />
                </TableContainer>
            </ButtonsAndTable >
        </>
    )
}