import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Container } from "../../components/Container"
import { BackButtonAndTitle } from "../../components/BackButtonAndTitle"
import { TextField, InputLabel, Select, MenuItem, Button } from "@mui/material"
import { FormContainer } from "../../components/form/FormContainer"
import { TextFieldContainer } from "../../components/form/TextFieldContainer"

import { AddressAutoComplete } from "../../components/AddressAutoComplete"
import { useState } from "react"
import { Place } from "../../types/Place"
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loader } from '../../components/Loader';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { loader } from 'graphql.macro';
const CREATE_COLLECT_POINT = loader("../../graphql/collectPoint/mutation/createCollectPoint.graphql");

const CPtypes = ["GMS", "PROXI", "CHR", "OTHER"]

export const CreateCollectPoint = () => {
    const navigate = useNavigate()
    const [addressError, setAddressError] = useState<string>()
    let [createCollectPoint, { data, loading, error }] = useMutation(CREATE_COLLECT_POINT);
    const [selectedPlace, setSelectedPlace] = useState<Place>({
        address: "",
        zipCode: "",
        city: "",
        latitude: 0,
        longitude: 0
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            type: "GMS",
            address: "",
            zipCode: "",
            city: "",
            latitude: 0,
            longitude: 0
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Le nom du point de collecte est requis'),
            type: Yup.string().required('Le type du point de collecte est requis'),
        }),
        onSubmit: async values => {
            values.address = selectedPlace.address
            values.zipCode = selectedPlace.zipCode
            values.city = selectedPlace.city
            values.latitude = selectedPlace.latitude
            values.longitude = selectedPlace.longitude
            if (values.address == "" || (values.latitude == 0 && values.longitude == 0) || values.city == "") {
                return setAddressError("L'adresse est incomplète")
            }

            await createCollectPoint({
                variables: values,
                onCompleted: (data) => {
                    formik.resetForm();
                    navigate(-1)
                }
            })
        }
    })

    return (
        <Container>
            <BackButtonAndTitle title="Nouveau point de collecte" />
            <FormContainer>
                <form onSubmit={formik.handleSubmit}>
                    <TextFieldContainer>
                        <InputLabel>Nom du point de collecte</InputLabel>
                        <TextField
                            id="name"
                            label="Nom"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            {...formik.getFieldProps('name')}
                            error={formik.touched.name && formik.errors.name ? true : false}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </TextFieldContainer>

                    <TextFieldContainer>
                        <InputLabel>Type</InputLabel>
                        <Select
                            fullWidth label="Type"
                            {...formik.getFieldProps('type')}
                        >
                            {CPtypes.map((type: any) => {
                                return (
                                    <MenuItem value={type}>{type}</MenuItem>
                                )
                            })
                            }
                        </Select>
                    </TextFieldContainer>

                    <TextFieldContainer>
                        <AddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </TextFieldContainer>

                    {error && <ErrorMessage>Une errreur est survenue...</ErrorMessage>}
                    {addressError && <ErrorMessage>{addressError}</ErrorMessage>}
                    {!loading && <Button variant="contained" style={{ marginTop: 20 }} type="submit">Créer l'entreprise</Button>}
                    {loading && <Loader />}
                </form>
            </FormContainer>
        </Container>
    )
}