import { IconButton, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

export const ProductsInCollapseTable = (props: any) => {
    const navigate = useNavigate();

    return (
        <TableContainer style={{ width: "100%", backgroundColor: "#eee" }}>
            <Table size="small">
                {props.rows.map((product: any) => {
                    return (
                        <TableRow style={{ display: 'flex' }}>
                            <TableCell style={{ flex: 1 }} align='left'>{product.name}</TableCell>
                            <TableCell style={{ flex: 1 }} align='left'>{product.EAN}</TableCell>
                            <TableCell style={{ flex: 1 }} align='left'>{product.packaging.name}</TableCell>
                            <TableCell style={{ flex: 1 }} align='left'>{product.deposit}€</TableCell>
                            <TableCell style={{ flex: 1 }} align="left">{product.isRecommended ? "Recommandé" : ""}</TableCell>
                            <TableCell style={{}}><IconButton onClick={() => navigate(`/produits/${product.id}`)}><EditIcon /></IconButton></TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        </TableContainer>
    )
}