import styled from "styled-components"
import { useEffect } from "react"
import { computeCollectPointFillRate } from "../../utils/computeCollectPointFillRate"

const Map = styled.div`
    flex :1;
    background-color:red;
    height:100%;
    width:100%
`

export const CollectPointMap = (props: any) => {
    const collectPoints = props.collectPoints
    console.log("collectPoint on my map: ", collectPoints)
    useEffect(() => {
        const map = initMap()
        populateMap(map, collectPoints)
        return
    }, [])

    return (
        <Map id='map'></Map>
    )
}

function initMap(): google.maps.Map {
    const caen = { lat: 49.182191820170736, lng: -0.37297695948749215 };
    const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
            zoom: 13,
            center: caen,
        }
    );

    // remove default shop from google map
    map.setOptions({ styles: [{ featureType: "poi", stylers: [{ visibility: "off" }] }] });

    return map
}

const populateMap = (map: google.maps.Map, collectePoints: any[]): void => {
    // let lastOpenedInfoWindow: google.maps.InfoWindow | null = null;

    collectePoints.forEach((collectePoint) => {
        const marker = createCustomMarker(collectePoint, map);
        const infoWindow = createInfoWindow(collectePoint);

        marker.addListener("click", () => {
            // if (lastOpenedInfoWindow) {
            //     lastOpenedInfoWindow.close();
            // }
            infoWindow.open(map, marker);
            // lastOpenedInfoWindow = infoWindow;
        });
    });

    // zoom map to fit all markers
    const bounds = new google.maps.LatLngBounds();
    collectePoints.forEach((collectePoint) => {
        bounds.extend({ lat: Number(collectePoint.location.latitude), lng: Number(collectePoint.location.longitude) });
    });
    map.fitBounds(bounds);
}

const createCustomMarker = (collectePoint: any, map: google.maps.Map) => {
    const fillRate = computeCollectPointFillRate(collectePoint);
    return new google.maps.Marker({
        position: { lat: collectePoint.location.latitude, lng: collectePoint.location.longitude },
        title: collectePoint.name,
        map: map,
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 20,
            fillColor: fillColor(fillRate),
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "grey",
        },
        label: {
            text: fillRate.toString() + "%",
            color: "white",
            fontSize: "14px",
        }
    });
}

const fillColor = (saturationRate: number): string => {
    if (saturationRate < 50) {
        return "#71C562";
    } else if (saturationRate < 75) {
        return "#FFA500";
    } else {
        return "#FF0000";
    }
};

const createInfoWindow = (collectPoint: any) => {
    const infoWindow = new google.maps.InfoWindow({
        content: `<div class="infoWindow">
        <h3>${collectPoint.name}</h3>
        <p>${collectPoint.location.address}</p>
        <p>${collectPoint.location.zipCode}</p>
        <p>${collectPoint.location.city}</p>
        <a href="/points-de-collecte/${collectPoint.id}">Voir plus</a>
        </div>`,
    });

    return infoWindow;
}