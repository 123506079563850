import { apolloClient } from "../apolloClient"
import { loader } from 'graphql.macro'

const COMPANY = loader("../../graphql/company/query/company.graphql");

export const getCompany = async (id: number) => {
    const { data } = await apolloClient.query({
        query: COMPANY,
        variables: {
            companyId: id
        }
    })

    return { company: data.company }
}