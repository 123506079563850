import { CustomModal } from "../../components/CustomModal"
import { Button, InputLabel, TextField, useScrollTrigger, Switch } from "@mui/material"
import { useMutation } from "@apollo/client";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { loader } from 'graphql.macro';
import { Loader } from "../../components/Loader";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CREATE_COMPANY = loader("../../graphql/company/mutations/createCompany.graphql");

export const CreateCompanyModal = (props: any) => {
    let [createCompany, { data, loading, error }] = useMutation(CREATE_COMPANY);

    let [isCollectPoint, setIsCollectPoint] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            address: '',
            zipCode: '',
            city: '',
            latitude: 0,
            longitude: 0,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Le nom de l\'entreprise est obligatoire'),
            email: Yup.string().email('Email invalide').required('L\'email est obligatoire'),
            phone: Yup.string().required('Le téléphone est obligatoire'),
            address: Yup.string().required('L\'adresse est obligatoire'),
            zipCode: Yup.string().required('Le code postal est obligatoire'),
            city: Yup.string().required('La ville est obligatoire'),
            latitude: Yup.number().required('La latitude est obligatoire'),
            longitude: Yup.number().required('La longitude est obligatoire'),
        }),
        onSubmit: async values => {
            values.latitude = Number(values.latitude)
            values.longitude = Number(values.longitude)
            await createCompany({
                variables: { ...values, ...{ isCollectPoint } },
                onCompleted: (data) => {
                    props.setOpenModal(false);
                    formik.resetForm();
                    navigate("/entreprises")
                }
            })
        }
    })

    return (
        <CustomModal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <h2>Créer une entreprise</h2>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    id="name"
                    label="Nom de l'entreprise"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('email')}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                    id="phone"
                    label="Téléphone"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('phone')}
                    error={formik.touched.phone && formik.errors.phone ? true : false}
                    helperText={formik.touched.phone && formik.errors.phone}
                />
                <TextField
                    id="address"
                    label="Adresse"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('address')}
                    error={formik.touched.address && formik.errors.address ? true : false}
                    helperText={formik.touched.address && formik.errors.address}
                />
                <TextField
                    id="zipCode"
                    label="Code postal"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('zipCode')}
                    error={formik.touched.zipCode && formik.errors.zipCode ? true : false}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
                <TextField
                    id="city"
                    label="Ville"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('city')}
                    error={formik.touched.city && formik.errors.city ? true : false}
                    helperText={formik.touched.city && formik.errors.city}
                />
                <TextField
                    id="latitude"
                    label="Latitude"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('latitude')}
                    error={formik.touched.latitude && formik.errors.latitude ? true : false}
                    helperText={formik.touched.latitude && formik.errors.latitude}
                />
                <TextField
                    id="longitude"
                    label="Longitude"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    {...formik.getFieldProps('longitude')}
                    error={formik.touched.longitude && formik.errors.longitude ? true : false}
                    helperText={formik.touched.longitude && formik.errors.longitude}
                />
                <div style={{ display: "flex", alignItems: 'center' }}>
                    <InputLabel>Point de collecte </InputLabel>
                    <Switch
                        checked={isCollectPoint}
                        onChange={() => setIsCollectPoint(!isCollectPoint)}
                    />
                </div>
                {error && <ErrorMessage>Une errreur est survenue...</ErrorMessage>}
                {!loading && <Button variant="contained" style={{ marginTop: 20 }} type="submit">Créer l'entreprise</Button>}
                {loading && <Loader />}
            </form>
        </CustomModal>
    )
}