import { useState } from "react";
import { CustomModal } from "../../components/CustomModal"
import { Button, InputLabel, MenuItem, Select, TextField, useScrollTrigger, Autocomplete, OutlinedInput, Switch } from "@mui/material"
import { useMutation } from "@apollo/client";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { loader } from 'graphql.macro';

import { Loader } from "../../components/Loader";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useLoaderData, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Form = styled.form`
    gap:10px;
    display:grid;
`

const CREATE_PRODUCT = loader('../../graphql/product/mutation/createProduct.graphql');

export const CreateProductModal = (props: any) => {
    const navigate = useNavigate()
    const { companies, packagings, productTypes } = useLoaderData() as any;
    const [createProduct, { loading, error }] = useMutation(CREATE_PRODUCT)
    const [isRecommended, setIsRecommended] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: null,
            companyId: null,
            type: null,
            packagingId: null,
            ean: null,
            deposit: 0.05,
            imageUrl: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Champ requis'),
            companyId: Yup.number().required('Champ requis'),
            type: Yup.string().required('Champ requis'),
            packagingId: Yup.number().required('Champ requis'),
            ean: Yup.string().required('Champ requis'),
            deposit: Yup.number().min(0, "la consigne doit être supérieur ou égale à 0").required('Champ requis'),
            imageUrl: Yup.string(),
        }),
        onSubmit: async (values) => {
            await createProduct({
                variables: {
                    ...values,
                    ...{
                        deposit: Number(values.deposit),
                        isRecommended: isRecommended
                    },
                },
                onCompleted: () => {
                    props.setOpenModal(false)
                    formik.resetForm()
                    navigate("/produits")
                }
            })
        }
    })

    return (
        < CustomModal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <h2>Créer un nouveau produit</h2>

            <Form onSubmit={
                formik.handleSubmit
            }>
                <div>
                    <InputLabel>Nom *</InputLabel>
                    <TextField
                        id="name"
                        placeholder="Nom"
                        variant="outlined"
                        fullWidth
                        {...formik.getFieldProps('name')}
                        error={formik.touched.name && formik.errors.name ? true : false}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </div>


                <div>
                    <InputLabel>Entreprise *</InputLabel>
                    <Autocomplete
                        id="company"
                        options={companies}
                        getOptionLabel={(company: any) => company.name}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    placeholder="Entreprise"
                                    variant="outlined"
                                    error={formik.touched.companyId && formik.errors.companyId ? true : false}
                                    helperText={formik.touched.companyId && formik.errors.companyId}
                                />
                            )
                        }}
                        onChange={(event, value) => {
                            formik.setFieldValue('companyId', value.id)
                        }}
                    />
                </div>

                <div>
                    <InputLabel>Type *</InputLabel>
                    <Autocomplete
                        id="type"
                        options={productTypes}
                        getOptionLabel={(productType: any) => productType}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                placeholder="Type"
                                variant="outlined"
                                error={formik.touched.type && formik.errors.type ? true : false}
                                helperText={formik.touched.type && formik.errors.type}
                            />}
                        onChange={(event, value) => {
                            formik.setFieldValue('type', value)
                        }}
                    />
                </div>


                <div>
                    <InputLabel>Emballage *</InputLabel>
                    <Autocomplete
                        id="packaging"
                        options={packagings}
                        getOptionLabel={(packaging: any) => packaging.name}
                        renderInput={(params) => <TextField
                            {...params}
                            placeholder="Emballage"
                            variant="outlined"
                            error={formik.touched.packagingId && formik.errors.packagingId ? true : false}
                            helperText={formik.touched.packagingId && formik.errors.packagingId}
                        />
                        }
                        onChange={(event, value) => {
                            formik.setFieldValue('packagingId', value.id)
                        }}
                    />
                </div>

                <div>
                    <InputLabel>EAN * (sans espace)</InputLabel>
                    <TextField
                        id="EAN"
                        placeholder="EAN"
                        variant="outlined"
                        fullWidth
                        {...formik.getFieldProps('ean')}
                        error={formik.touched.ean && formik.errors.ean ? true : false}
                        helperText={formik.touched.ean && formik.errors.ean}
                    />
                </div>

                <div>
                    <InputLabel>Consigne (€) *</InputLabel>
                    <OutlinedInput
                        id="deposit"
                        placeholder="Consigne"
                        fullWidth
                        startAdornment="€"
                        type="number"
                        {...formik.getFieldProps('deposit')}
                        error={formik.touched.deposit && formik.errors.deposit ? true : false}
                    />
                </div>

                <div>
                    <InputLabel>url de l'image (optionnel)</InputLabel>
                    <TextField
                        id="imageUrl"
                        placeholder="url de l'image"
                        variant="outlined"
                        fullWidth
                        {...formik.getFieldProps('imageUrl')}
                        error={formik.touched.imageUrl && formik.errors.imageUrl ? true : false}
                        helperText={formik.touched.imageUrl && formik.errors.imageUrl}
                    />
                </div>

                <div style={{ display: "flex", alignItems: 'center' }}>
                    <InputLabel>Produit Recommandé </InputLabel>
                    <Switch
                        checked={isRecommended}
                        onChange={() => setIsRecommended(!isRecommended)}
                    />
                </div>
                {!loading && <Button variant="contained" style={{ marginTop: 20 }} type="submit">Créer le produit</Button>}
                {loading || formik.isSubmitting && <Loader />}
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </Form>
        </CustomModal >
    )
}