import { Modal } from "@mui/material";
import { Paper } from "./Paper";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: "80%",
    bgcolor: 'background.paper',
    border: '1px solid #555',
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    display: 'block',
    justifyContent: 'center',
    flexDirection: 'column' as 'column',
    overflow: "scroll",
};

export const CustomModal = (props: any) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper style={style}>
                {props.children}
            </Paper>
        </Modal>
    )
}