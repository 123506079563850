import styled from "styled-components"
import { Chip } from "../../../components/Chip"

const Wrapper = styled.div`
    display:flex;
    gap:10px;
    align-items:center
`


export const SortingCenter = ({ center, isOwner }: any) => {
    return (
        <Wrapper>
            <p>{center.name}</p>
            {isOwner ? <Chip color="blue">Propriétaire</Chip> : null}
        </Wrapper>
    )
}