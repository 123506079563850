import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { theme } from '../Theme/theme'

const Wrapper = styled.div`
    display : flex
`

const BackButtonWrapper = styled.div`
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-left:10px;
    padding-right:10px
`

export const BackButtonAndTitle = (props: any) => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <BackButtonWrapper onClick={() => {
                if (props.backLink) {
                    return navigate(props.backLink)
                }
                navigate(-1)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke={theme.palette.primary.main} stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M19 12H5M12 19l-7-7 7-7" />
                </svg>
            </BackButtonWrapper>
            <h2>{props.title}</h2>
        </Wrapper>
    )
}